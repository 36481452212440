import React from "react";
import { mdiMinus, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import MenuUrl from "assets/mobile/ProductsPage/menu.svg";
import CartUrl from "assets/mobile/ProductsPage/cart.svg";
import NavButtonLink from "components/NavButtonLink";
import PathsAPI from "constants/PathsAPI";
import { useFetch } from "hooks/useFetch";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "styles/mobile/Dashboard/User/Shop/Products.module.css";
import { useAuthContext } from "hooks/useAuthContext";

import timeToDate from "constants/timeToDate";
import Button from "components/Button";
import summaryAmountProducts from "helpers/summaryAmountProducts";
import FilterProducts from "helpers/FilterProducts";

const ProductsPage = () => {
	const params = useParams();
	const location = useLocation();
	const navigate = useNavigate();

	const { useGet } = useFetch(`${PathsAPI.Products}?device=${params.id}`);
	const { response: productsList } = useGet(true);

	const { useGet: useKioskGet } = useFetch(`${PathsAPI.Products}/kiosk?device=${params.id}`);
	const { response: kioskProductsList } = useKioskGet(true);

	const { usePut: useOrderCartPut, useGet: useOrderCartGet } = useFetch(PathsAPI.OrderUserCart(params.id));
	const { request: updateCart } = useOrderCartPut();
	const { response: savedCart } = useOrderCartGet(true);

	const [filterCategory, setFilterCategory] = useState("ALL");
	const [filterShop, setFilterShop] = useState("");
	const [filteredProducts, setFilteredProducts] = useState(false);
	const [amountProducts, setAmountProducts] = useState({});

	const { user, orderDate } = useAuthContext();

	useEffect(() => {
		savedCart && setAmountProducts(savedCart.amount);
	}, [savedCart]);

	useEffect(() => {
		if (!productsList || !kioskProductsList) {
			return;
		}

		const filterProducts = new FilterProducts(productsList, kioskProductsList, orderDate);
		setFilteredProducts(filterProducts.filter(filterCategory, filterShop));
	}, [productsList, filterCategory, filterShop, kioskProductsList, orderDate]);

	const handleAddToCart = async (itemId, isVoucher = false) => {
		try {
			const newAmountProducts = await updateCart({ itemId, isVoucher, amount: 1 });
			setAmountProducts(newAmountProducts);
			return true;
		} catch (error) {
			toast.warning("Wystąpił problem z dodaniem produktu do koszyka");
		}
	};

	const handleChangeAmount = async (productId, newAmount) => {
		try {
			const newAmountProducts = await updateCart({ itemId: productId, amount: newAmount });
			setAmountProducts(newAmountProducts);
		} catch (error) {
			toast.warning("Wystąpił problem z zmianą produktów w koszyku");
		}
	};

	useEffect(() => {
		if (location.state?.mode === "CATEGORY") {
			setFilterCategory(location.state.name);
			setFilterShop(location.state.shop);
			location.state = null;
		}
	}, [location, setFilterCategory, setFilterShop]);

	const getCategoryTitle = (rawCategory) => {
		switch (rawCategory) {
			case "ALL":
				return "Wszystkie";
			case "VENDING":
				return "Dostępne na miejscu";
			default:
				return rawCategory;
		}
	};

	const inKioskProduct = (productId) => {
		if (!kioskProductsList) {
			return;
		}

		const res = kioskProductsList.find((kioskProductId) => {
			return kioskProductId === productId;
		});

		return res ? (
			<div className={styles.productInKioskContainer}>
				DOSTĘPNE
				<br />
				NA MIEJSCU
			</div>
		) : (
			false
		);
	};

	const getWeekDay = (date = new Date(orderDate)) => {
		return date.toLocaleString("pl", { weekday: "short" }).toUpperCase().slice(0, -1);
	};

	const checkAvailabilityWeekDays = (product, checkedDate = orderDate) => {
		for (let i = 1; i <= 7; i++) {
			let nextDay = new Date(checkedDate);
			nextDay.setDate(nextDay.getDate() + i);

			if (nextDay < new Date(product.startDeliveryDate)) {
				nextDay = new Date(product.startDeliveryDate);
			}

			if (nextDay > new Date(product.endDeliveryDate)) {
				nextDay = new Date(product.endDeliveryDate);
			}

			if (product.shopName.weekDays.includes(getWeekDay(nextDay))) {
				return nextDay;
			}
		}

		return new Date(product.endDeliveryDate);
	};

	const checkAvailability = (product) => {
		if (!user) {
			return;
		}

		if (filterCategory === "VENDING") {
			return;
		}

		let potentialStartDeliveryDate = new Date(product.startDeliveryDate);

		if (!product.shopName.weekDays.includes(getWeekDay())) {
			potentialStartDeliveryDate = checkAvailabilityWeekDays(product);
		}

		if (orderDate.toDateString() === new Date().toDateString() && new Date() > timeToDate(product.shopName.maxOrdering)) {
			potentialStartDeliveryDate = checkAvailabilityWeekDays(product);
		}

		let endDeliveryDate = new Date(product.endDeliveryDate);
		endDeliveryDate.setHours(23, 59, 59, 999);

		if (potentialStartDeliveryDate.getTime() === new Date(product.startDeliveryDate).getTime()) {
			potentialStartDeliveryDate.setHours(0, 0, 0, 0);

			if (orderDate.toDateString() === new Date().toDateString()) {
				const time = product.shopName.maxOrdering.split(":");
				endDeliveryDate.setHours(time[0], time[1], 0, 0);
			}
		}

		if (orderDate < potentialStartDeliveryDate || orderDate > endDeliveryDate) {
			return (
				<div style={{ color: "#3c3c3b" }} className={styles.productAvailabilityContainer}>
					DOSTĘPNE {potentialStartDeliveryDate.toLocaleDateString("pl", { day: "2-digit", month: "2-digit" })}
					{potentialStartDeliveryDate.toDateString() === new Date().toDateString() && (
						<>
							<br />
							DO {timeToDate(product.shopName.maxOrdering).toLocaleTimeString("pl", { hour: "2-digit", minute: "2-digit" })}
						</>
					)}
					{inKioskProduct(product._id)}
				</div>
			);
		}

		return (
			<>
				<div className={styles.productAvailabilityContainer}>
					DOSTĘPNE {new Date(orderDate).toLocaleDateString("pl", { day: "2-digit", month: "2-digit" })}
					{orderDate.toDateString() === new Date().toDateString() && (
						<>
							<br />
							DO {timeToDate(product.shopName.maxOrdering).toLocaleTimeString("pl", { hour: "2-digit", minute: "2-digit" })}
						</>
					)}
					{inKioskProduct(product._id)}
				</div>
				<div className={styles.productAddToCartContainer}>
					{amountProducts && amountProducts?.[product._id] > 0 ? (
						<div className={styles.productCartControls}>
							<Icon
								style={{
									height: "1rem",
									width: "1rem",
									cursor: "pointer",
									background: "var(--primary)",
									color: "#fff",
									borderRadius: "8px",
									padding: "4px",
								}}
								path={mdiMinus}
								onClick={() => {
									handleChangeAmount(product._id, amountProducts[product._id] - 1);
								}}
							/>
							<div style={{ textAlign: "center", padding: "0 0.5em" }}>{amountProducts[product._id]}</div>
							<Icon
								style={{
									height: "1rem",
									width: "1rem",
									cursor: "pointer",
									background: "var(--primary)",
									color: "#fff",
									borderRadius: "8px",
									padding: "4px",
								}}
								path={mdiPlus}
								onClick={() => {
									handleChangeAmount(product._id, amountProducts[product._id] + 1);
								}}
							/>
						</div>
					) : (
						<div
							style={{
								cursor: "pointer",
								background: "var(--primary)",
								color: "#fff",
								borderRadius: "8px",
								padding: "4px",
							}}
							onClick={async () => {
								if (product.blockAmount) {
									await handleAddToCart(product.voucherId, true);
									navigate("cart");
								} else {
									handleAddToCart(product._id);
								}
							}}
						>
							{product.blockAmount ? "Wykorzystaj voucher" : "Dodaj do koszyka"}
						</div>
					)}
				</div>
			</>
		);
	};

	const redirectToSelectDate = () => {
		navigate(`/dashboard/shop/online/products/${params.id}/order-date`);
	};

	return (
		<div className={styles.container}>
			<header className={styles.header}>
				<NavButtonLink to="categories" className={styles.iconButton}>
					<img src={MenuUrl} alt="menu" />
				</NavButtonLink>

				<NavButtonLink to="cart" state={location.state} className={styles.iconButton}>
					<img src={CartUrl} alt="cart" />
					<div className={styles.productCounter}>{summaryAmountProducts(amountProducts)}</div>
				</NavButtonLink>
			</header>

			<div className={styles.titleContainer}>
				<h1 style={{ margin: 0 }}>Wybierz produkty</h1>
				<h2 style={{ color: "var(--primary)", margin: 0 }}>{getCategoryTitle(filterCategory)}</h2>
				<Button className={styles.changeDateButton} onClick={redirectToSelectDate}>
					{orderDate.toLocaleDateString("pl")}
					<br />
					Zmień datę zamówienia
				</Button>
			</div>

			<div className={styles.productsContainer}>
				{!filteredProducts ? (
					<h3 style={{ textAlign: "center" }}>Ładowanie ..</h3>
				) : productsList && filteredProducts.length > 0 ? (
					filteredProducts.map((product) => (
						<div key={product._id} className={styles.productContainer}>
							<img
								loading="lazy"
								alt={product.name}
								src={product.photoUrl}
								onError={(e) => {
									e.target.src = "/404.png";
								}}
							></img>
							<div className={styles.productInfo}>
								<div className={styles.productTitle}>
									<b style={{ fontSize: "1.1em" }}>{product.name}</b>
									{checkAvailability(product)}
								</div>
								<div className={styles.productDesc}>
									<div style={{ fontWeight: 500, fontSize: "0.7em" }}>{product.desc}</div>
								</div>
								<b style={{ fontSize: "1.2em" }}>{product.blockAmount ? "Voucher" : `${product.brutto} zł`}</b>
							</div>
						</div>
					))
				) : (
					<h3 style={{ textAlign: "center" }}>
						Brak dostępnych produktów
						<br />w tej kategorii
					</h3>
				)}
			</div>
			<Outlet />
		</div>
	);
};

export default ProductsPage;
