import React from "react";
import { useNavigate } from "react-router-dom";
import styles from 'styles/desktop/components/NavItem/NavItem.module.css'
const NavItem = (props) => {

    const { to, title, bold } = props;
    let navigate = useNavigate();
    return (
        <div className={[styles.navItem, bold ? styles.navItem_bolded : ""].join(" ")} onClick={() => {
            navigate(to);
        }}>
            {title}
        </div>
    )
}

export default NavItem;