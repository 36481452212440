import timeToDate from "constants/timeToDate";

class DeliveryDates {
	constructor(shopsInfo, productList) {
		this.shopsInfo = shopsInfo;
		this.productList = productList;
		const { startShop, closeShop, maxOrdering, weekDays } = this.deliveryTimeRange();
		this.startShop = startShop;
		this.closeShop = closeShop;
		this.maxOrdering = maxOrdering;
		this.weekDays = weekDays;
	}

	deliveryTimeRange = () => {
		const { shopsInfo, productList } = this;

		let maxOrdering = 0;
		let reducedWeekDays = ["PON", "WT", "ŚR", "CZW", "PT", "SO", "ND"];
		const shops = productList.map((product) => product.supplier);

		let startShop = 0;
		let closeShop = 0;

		for (const shopInfo of shopsInfo.filter((shopInfo) => shops.includes(shopInfo._id))) {
			let tempMaxOrdering = new Date(timeToDate(shopInfo.maxOrdering)).getTime();

			if (maxOrdering === 0) {
				maxOrdering = tempMaxOrdering;
			} else if (tempMaxOrdering < maxOrdering) {
				maxOrdering = tempMaxOrdering;
			}
			reducedWeekDays = reducedWeekDays.filter((day) => shopInfo.weekDays.includes(day));

			let tempStartShop = new Date(timeToDate(shopInfo.openTime)).getTime();
			let tempCloseShop = new Date(timeToDate(shopInfo.closeTime)).getTime();

			if (closeShop < tempCloseShop) {
				startShop = tempStartShop;
				closeShop = tempCloseShop;
			}
		}

		startShop = new Date(startShop);
		closeShop = new Date(closeShop);
		maxOrdering = new Date(maxOrdering);

		const currentTime = new Date();
		const secondsToStart = Math.round((startShop.getTime() - currentTime.getTime()) / 1000);

		if (secondsToStart < 3600) {
			startShop = new Date(startShop.getTime() + (3600 - secondsToStart) * 1000);
		}

		return { startShop, closeShop, maxOrdering, weekDays: reducedWeekDays };
	};

	getStartDeliveryTime = (orderDate) => {
		const { startShop, maxOrdering } = this;

		let tempDeliveryTime = new Date(orderDate);

		if (orderDate.toDateString() !== new Date().toDateString()) {
			tempDeliveryTime.setHours(startShop.getHours());
			tempDeliveryTime.setMinutes(startShop.getMinutes());

			return tempDeliveryTime;
		}

		if (new Date().getTime() > maxOrdering.getTime()) {
			return false;
		}

		if (new Date().getTime() < startShop.getTime()) {
			tempDeliveryTime.setHours(startShop.getHours());
			tempDeliveryTime.setMinutes(startShop.getMinutes());
		} else {
			tempDeliveryTime.setHours(new Date().getHours());
			tempDeliveryTime.setMinutes(new Date().getMinutes());
		}

		return tempDeliveryTime;
	};

	potentialAvailableDays = () => {
		let minDate = null;
		let maxDate = null;

		for (let i = 0; i < 7; i++) {
			let tmpDate = new Date();
			tmpDate.setDate(tmpDate.getDate() + i);
			if (this.weekDays.includes(tmpDate.toLocaleString("pl", { weekday: "short" }).toUpperCase().slice(0, -1))) {
				if (minDate === null) {
					minDate = tmpDate;
					continue;
				} else {
					tmpDate.setDate(tmpDate.getDate() + 1);
					maxDate = tmpDate;
					break;
				}
			}
		}

		if (maxDate === null) {
			maxDate = new Date();
			maxDate.setDate(maxDate.getDate() + 7);
		}

		return { min: minDate, max: maxDate };
	};
}

export default DeliveryDates;
