import React from "react";
import NavButtonLink from "components/NavButtonLink";
import styles from "styles/desktop/pages/Shop/CartPage.module.css";

import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import { toast } from "react-toastify";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar, MobileTimePicker } from "@mui/x-date-pickers";
import { plPL } from "@mui/x-date-pickers/locales";
import locale from "date-fns/locale/pl";

import { useAuthContext } from "hooks/useAuthContext";
import CategoriesPage from "./CategoriesPage";
import Heading from "components/Heading";
import { ReactComponent as TrashIcon } from "assets/icons/icon-trash.svg";
import { TextField } from "@mui/material";
import CartUrl from "assets/desktop/ProductsPage/cart.svg";
import DeliveryDates from "helpers/DeliveryDates";
import formatDateTime from "helpers/formatDateTime";
import summaryAmountProducts from "helpers/summaryAmountProducts";
import AvailabilityChecker from "pages/mobile/Dashboard/User/Shop/checkAvailability";

const CartPage = () => {
	const params = useParams();
	const location = useLocation();
	const navigate = useNavigate();
	const [productList, setProductList] = useState([]);
	const [amountProducts, setAmountProducts] = useState({});
	const [noteProducts, setNoteProducts] = useState({});
	const [summary, setSummary] = useState("0");
	const { orderDate } = useAuthContext();
	const [deliveryTime, setDeliveryTime] = useState(false);
	const [shopIsClosed, setShopIsClosed] = useState(false);
	const [deliveryTimeRange, setDeliveryTimeRange] = useState();
	const [device, setDevice] = useState({});
	const { usePost: usePostSendOrder } = useFetch(PathsAPI.UserOrders);
	const { request: reqSendOrder, loading: loadingSendOrder } = usePostSendOrder();
	const [openTimePicker, setOpenTimePicker] = useState(false);
	const [openDateTimePicker, setOpenDateTimePicker] = useState(true);
	const [datePickerDateRange, setDatePickerDateRange] = useState({ min: new Date(), max: new Date() });
	const { useGet } = useFetch(`${PathsAPI.Suppliers}?device=${params.id}`);
	const { response: shopsInfo } = useGet(true);

	const availabilityChecker = new AvailabilityChecker(orderDate, shopsInfo);

	const { useGet: useDeviceGet } = useFetch(`${PathsAPI.Devices}/user`);
	const { response: deviceList } = useDeviceGet(true);
	const [blockCart, setBlockCart] = useState(false);

	const { usePut: usePutSendVoucherOrder } = useFetch(PathsAPI.VoucherUser);
	const { request: reqSendVoucherOrder, loading: loadingSendVoucherOrder } = usePutSendVoucherOrder();

	const { usePut: useOrderCartPut, useGet: useOrderCartGet } = useFetch(PathsAPI.OrderUserCart(params.id, "cart"));
	const { request: updateCart } = useOrderCartPut();
	const { response: savedCart } = useOrderCartGet(true);

	const [minCartCostList, setMinCartCostList] = useState({});

	useEffect(() => {
		if (!savedCart || !shopsInfo) {
			return;
		}

		const { amount, products, summary: newSummary, minCartCost } = savedCart;

		for (const [supplier, value] of Object.entries(minCartCost)) {
			if (typeof value === "number") {
				const shopData = shopsInfo.find((shop) => shop._id === supplier);
				toast.warn(
					<>
						Brakuje <b>{value.toFixed(2)} PLN</b> do minimalnego koszyka od dostawcy <b>{shopData.name}</b>
					</>
				);
			}
		}

		setProductList(products);
		setAmountProducts(amount);
		setSummary(newSummary);
		setMinCartCostList(minCartCost);
	}, [shopsInfo, savedCart]);

	useEffect(() => {
		if (!deviceList) {
			return;
		}

		const { _id, locatedAt } = deviceList.find((d) => d._id === params.id);

		setDevice({ id: _id, location: locatedAt });
	}, [deviceList, setDevice, params.id]);

	useEffect(() => {
		if (!shopsInfo) {
			return;
		}

		if (!shopsInfo) {
			return;
		}

		if (!(productList.length > 0)) {
			return;
		}

		const deliveryDates = new DeliveryDates(shopsInfo, productList);

		setDeliveryTimeRange({ timeMin: deliveryDates.startShop, timeMax: deliveryDates.closeShop });

		const startDeliveryTime = deliveryDates.getStartDeliveryTime(orderDate);

		if (startDeliveryTime) {
			setDeliveryTime(startDeliveryTime);
		} else {
			setShopIsClosed(true);
			setDatePickerDateRange(deliveryDates.potentialAvailableDays());
		}
	}, [shopsInfo, productList, params.id, orderDate, shopIsClosed]);

	useEffect(() => {
		if (location?.state?.mode === "CATEGORY") {
			navigate(`/dashboard/shop/online/products/${params.id}/`, { state: location.state });
		}
	}, [location, navigate, params.id]);

	const changeCartItemQuantity = async (productId, newAmount) => {
		const { amount, products, summary: newSummary, minCartCost } = await updateCart({ itemId: productId, amount: newAmount, mode: "all" });

		setProductList(products);
		setAmountProducts(amount);
		setSummary(newSummary);
		setMinCartCostList(minCartCost);

		for (const [supplier, value] of Object.entries(minCartCost)) {
			if (typeof value === "number") {
				const shopData = shopsInfo.find((shop) => shop._id === supplier);
				toast.warn(
					<>
						Brakuje <b>{value.toFixed(2)} PLN</b> do minimalnego koszyka od dostawcy <b>{shopData.name}</b>
					</>
				);
			}
		}
	};

	useEffect(() => {
		if (!amountProducts) {
			return;
		}

		if (!blockCart && Object.values(amountProducts).includes("voucher")) {
			setBlockCart(true);
		}
	}, [amountProducts, blockCart]);

	const handleSendOrder = async () => {
		if (blockCart) {
			try {
				const note = Object.values(noteProducts)[0] || "";

				const { status, returnToken } = await reqSendVoucherOrder({ device, deliveryTime, note });
				if (status === "ok") {
					navigate(`/dashboard/shop/online/pay/success/${returnToken}`);
				} else {
					navigate(`/dashboard/shop/online/pay/failure/${returnToken}`);
				}
			} catch (error) {
				toast.error(error);
			}
		} else {
			try {
				const { paymentUrl } = await reqSendOrder({
					device,
					deliveryTime,
					notes: noteProducts,
					supplier: params.id,
				});
				if (paymentUrl) {
					window.location.href = paymentUrl;
				} else {
					toast.error("Wystąpił problem z złożeniem zamówienia");
				}
			} catch (error) {
				toast.error(error);
			}
		}
	};

	const handleChangeDeliveryTime = (value) => {
		if (value.getHours() === deliveryTimeRange.timeMax.getHours()) {
			value.setMinutes(0);
		}

		setDeliveryTime(value);
	};

	const returnNameColor = (product) => {
		if (!shopsInfo) {
			return "";
		}

		if (availabilityChecker.checkAvailability(product) !== true) {
			return "var(--error-color)";
		}

		if (typeof minCartCostList[product.supplier] === "number") {
			return "orange";
		}

		return "";
	};

	const onNoteChange = (e, productId) => {
		setNoteProducts((n) => ({ ...n, [productId]: e.target.value }));
	};

	const handleChangeDate = () => {
		setOpenDateTimePicker(!openDateTimePicker);
	};

	const handleChangeDeliveryDate = (value) => {
		setDeliveryTime(value);
		setOpenDateTimePicker(false);
		setShopIsClosed(false);
	};

	return (
		<div className={styles.container}>
			<div
				className={styles.overlay}
				onClick={() => {
					setOpenDateTimePicker(false);
				}}
				style={{ display: openDateTimePicker ? "block" : "none" }}
			></div>

			<div className={styles.content}>
				<div className={styles.categoriesPage}>
					<CategoriesPage />
				</div>
				<div className={styles.cartContainer}>
					<Heading style={{ fontSize: "3em" }}>Twój koszyk</Heading>
					<div className={styles.infoContainer}>
						<div className={styles.deviceLocation}>
							<h1>Lokalizacja urządzenia</h1>
							<p>{device.location}</p>
						</div>
						<div className={styles.deliveryTime}>
							<h1>Wybierz godzinę dostawy</h1>
							{!deliveryTime ? (
								<NavButtonLink className={styles.deliveryTimeButton}>Ładowanie ..</NavButtonLink>
							) : (
								<LocalizationProvider
									dateAdapter={AdapterDateFns}
									adapterLocale={locale}
									localeText={plPL.components.MuiLocalizationProvider.defaultProps.localeText}
								>
									{shopIsClosed === false ? (
										<>
											<NavButtonLink className={styles.deliveryTimeButton} onClick={() => setOpenTimePicker(true)}>
												{formatDateTime(deliveryTime)}
											</NavButtonLink>
											<MobileTimePicker
												title="Godzina dostawy"
												open={openTimePicker}
												views={["hours", "minutes"]}
												onClose={() => {
													setOpenTimePicker(false);
												}}
												value={deliveryTime}
												slots={{ field: () => <></> }}
												onChange={handleChangeDeliveryTime}
												renderInput={(params) => <TextField {...params} />}
												disablePast={deliveryTime.toDateString() === new Date().toDateString() ? true : false}
												minTime={deliveryTimeRange?.timeMin}
												maxTime={deliveryTimeRange?.timeMax}
											/>
										</>
									) : (
										<div className={styles.shopClosedContainer}>
											<p>Sklep jest zamknięty dnia {formatDateTime(deliveryTime, "date")}</p>
											<NavButtonLink className={styles.deliveryTimeButton} onClick={handleChangeDate}>
												Wybierz inny dzień
											</NavButtonLink>
											<DateCalendar
												className={`${openDateTimePicker ? styles.dateCalendarOpen : styles.dateCalendarClosed} ${styles.dateCalendar}`}
												value={deliveryTime}
												onChange={handleChangeDeliveryDate}
												disablePast={true}
												minDate={datePickerDateRange.min}
												maxDate={datePickerDateRange.max}
												views={["day"]}
											/>
										</div>
									)}
								</LocalizationProvider>
							)}
						</div>
					</div>
					{blockCart && (
						<h3 style={{ color: "red", textAlign: "center" }}>
							Uwaga
							<br />
							Powrót do menu usunie dodany voucher z koszyka!
						</h3>
					)}
					<div className={styles.cart}>
						{productList.length > 0 ? (
							productList.map((product, index) => (
								<div className={styles.cartItem} key={index}>
									<div className={styles.cartItemDescriptionContainer}>
										<div className={styles.cartItemDescription}>
											<img loading="lazy" className={styles.cartItemImage} alt={product.name} src={product.photoUrl} />
											<div className={styles.cartItemDescriptionInfo}>
												<h1 style={{ color: returnNameColor(product) }}>{product.name}</h1>
												<p>{product.desc}</p>
												<h1 className={styles.cartItemPrice}>
													{(
														(typeof amountProducts[product._id] === "number" ? amountProducts[product._id] : 1) * product.brutto
													).toFixed(2)}{" "}
													zł
												</h1>
											</div>
										</div>
										<div className={styles.cartItemDescriptionNoteToOrder}>
											<input
												type="text"
												placeholder="Uwagi do zamówienia"
												value={noteProducts[product._id]}
												onChange={(e) => onNoteChange(e, product._id)}
											/>
										</div>
									</div>
									<div className={styles.cartItemActions}>
										<div className={styles.cartItemActionsQuantity}>
											<NavButtonLink
												disabled={blockCart}
												onClick={() => {
													amountProducts[product._id] > 1 && changeCartItemQuantity(product._id, amountProducts[product._id] - 1);
												}}
											>
												-
											</NavButtonLink>
											<p>{amountProducts[product._id]}</p>
											<NavButtonLink
												disabled={blockCart}
												onClick={() => changeCartItemQuantity(product._id, amountProducts[product._id] + 1)}
											>
												+
											</NavButtonLink>
										</div>
										<div className={styles.cartItemActionsRemove} onClick={() => changeCartItemQuantity(product._id, 0)}>
											<TrashIcon />
										</div>
									</div>
								</div>
							))
						) : (
							<span className={styles.emptyCartMessage}>Twój koszyk jest pusty</span>
						)}
					</div>
					{productList.length > 0 && (
						<div className={styles.summary}>
							<p>Suma: {summary}zł</p>
							<NavButtonLink
								disabled={!(productList.length > 0) || loadingSendOrder || loadingSendVoucherOrder}
								className={styles.orderButton}
								onClick={() => handleSendOrder()}
							>
								Zamawiam
							</NavButtonLink>
						</div>
					)}
				</div>
				<div className={styles.cartIcon}>
					<div className={styles.cartIconAmount}>{summaryAmountProducts(amountProducts)}</div>
					<img src={CartUrl} style={{ height: "5em" }} alt="cart" />
				</div>
			</div>
		</div>
	);
};

export default CartPage;
