import React from "react";
import NavButtonLink from "components/NavButtonLink";
// import PathsAPI from "constants/PathsAPI";
// import { useFetch } from "hooks/useFetch";
// import { useParams } from "react-router-dom";

const FailurePaymentOnlinePage = () => {
	// const params = useParams();
	// const { useGet } = useFetch(`${PathsAPI.UserOrderPaymentCallback}/${params.token}`);
	// useGet(true);

	localStorage.removeItem("cart");

	return (
		<>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					height: "calc(100vh - var(--navbar-height) - var(--navigation-height) - 60px)",
					justifyContent: "center",
					padding: "30px",
				}}
			>
				<h1 style={{ color: "var(--primary)" }}>
					<span style={{ fontStyle: "italic" }}>Nieudana płatność</span> 😞
				</h1>
				<h2 style={{ textAlign: "center", fontWeight: "100" }}>
					Przykro nam, ale Twoja płatność nie powiodła się.
					<br />
					Sprawdź swoje dane i spróbuj ponownie lub skontaktuj się z nami, aby uzyskać pomoc.
				</h2>
				<NavButtonLink style={{ background: "var(--primary)", boxShadow: "unset", color: "white" }} to="/">
					Powrót
				</NavButtonLink>
			</div>
		</>
	);
};

export default FailurePaymentOnlinePage;
