import React from "react";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import userDashboardStyles from "styles/UserDashboard.module.css";

const PresentationPage = () => {
	const refreshColorAccent = () => {
		document.documentElement.style.setProperty("--background-color", "white");
		document.documentElement.style.setProperty("--background-accent", "#e7e7e7");
	};

	useEffect(refreshColorAccent, [refreshColorAccent]);

	return (
		<div className={userDashboardStyles.container}>
			<Outlet />
		</div>
	);
};

export default PresentationPage;
