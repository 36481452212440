import React from "react";
import { useSignup } from "hooks/useSignup";
import styles from "styles//desktop/pages/SignupPage/SignupPage.module.css";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Signup = () => {
	const { signup, error, success, isLoading } = useSignup();
	let navigate = useNavigate();

	const [passwordCheck, setPasswordCheck] = useState({ minLength: false, minLowercase: false, minUppercase: false, minNumbers: false, minSymbols: false });

	const handleSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);

		await signup(formData);
	};

	useEffect(() => {
		success && navigate("/login");
	}, [success, navigate]);

	const handleCheckPassword = (e) => {
		const checkValue = e.target.value;
		let result = { minLength: false, minLowercase: false, minUppercase: false, minNumbers: false, minSymbols: false };

		if (checkValue.length >= 8) {
			result.minLength = true;
		}

		if (checkValue !== checkValue.toUpperCase()) {
			result.minLowercase = true;
		}

		if (checkValue !== checkValue.toLowerCase()) {
			result.minUppercase = true;
		}

		if (/\d/.test(checkValue)) {
			result.minNumbers = true;
		}

		if (/[^\p{L}\d\s]/u.test(checkValue)) {
			result.minSymbols = true;
		}

		setPasswordCheck(result);
	};

	return (
		<div className={styles.container}>
			<h1>Zarejestruj się</h1>
			<form className={`${styles.form}`} onSubmit={handleSubmit}>
				<input type="text" required name="fname" placeholder="Imię" />
				<input type="text" required name="lname" placeholder="Nazwisko" />
				<input type="email" required name="email" placeholder="Email" />
				<input type="text" required name="phone" placeholder="Numer telefonu" />
				<input onChange={handleCheckPassword} type="password" required name="password" placeholder="Hasło" autoComplete="new-password" />
				<span className={styles.error}>{error?.message}</span>
				<fieldset style={{ marginTop: "1em" }}>
					<label htmlFor="statute">
						Regulamin (
						<a target="_blank" rel="noreferrer" href="/terms-and-conditions.pdf">
							sprawdź
						</a>
						)
					</label>
					<span />
					<input type="checkbox" name="statute" id="statute" required />
				</fieldset>
				<fieldset>
					<label htmlFor="private-policy">
						Polityka prywatności (
						<a target="_blank" rel="noreferrer" href="/private-policy.pdf">
							sprawdź
						</a>
						)
					</label>
					<span />
					<input type="checkbox" name="private-policy" id="private-policy" required />
				</fieldset>
				<Button className={styles.signUpButton} loading={isLoading}>
					Zarejestruj się
				</Button>
				<NavButtonLink className={styles.returnButton} to="/">
					Powrót
				</NavButtonLink>
				<div className={styles.passwordCheckContainer}>
					<h2>Wymagania hasła:</h2>
					<ul>
						<li style={{ color: passwordCheck.minLength ? "green" : "black" }}>
							<h2>minimum 8 znaków</h2>
						</li>
						<li style={{ color: passwordCheck.minLowercase ? "green" : "black" }}>
							<h2>małe litery</h2>
						</li>
						<li style={{ color: passwordCheck.minUppercase ? "green" : "black" }}>
							<h2>duże litery</h2>
						</li>
						<li style={{ color: passwordCheck.minNumbers ? "green" : "black" }}>
							<h2>cyfry</h2>
						</li>
						<li style={{ color: passwordCheck.minSymbols ? "green" : "black" }}>
							<h2>znaki specjalne</h2>
						</li>
					</ul>
				</div>
			</form>
		</div>
	);
};

export default Signup;
