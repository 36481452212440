import React from "react";
import { icon } from "leaflet";
import iconUrl from "assets/marker-icon.png";
import iconShadowUrl from "leaflet/dist/images/marker-shadow.png";

const CustomMarkerSearch = {
	icon: icon({
		iconUrl: iconUrl,
		shadowUrl: iconShadowUrl,
	}),
	draggable: false,
};

export default CustomMarkerSearch;
