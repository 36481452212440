import React from "react";
import logoUrl from "assets/mobile/logo.svg";
import titleUrl from "assets/mobile/TutorialPage/title.svg";
import step1Url from "assets/mobile/TutorialPage/step1.svg";
import step2Url from "assets/mobile/TutorialPage/step2.svg";
import step3Url from "assets/mobile/TutorialPage/step3.svg";
import step4Url from "assets/mobile/TutorialPage/step4.svg";
import NavButtonLink from "components/NavButtonLink";
import styles from "styles/mobile/Tutorial.module.css";
import buttonStyles from "styles/mobile/buttonStyles.module.css";

const MobileTutorial = () => {
	return (
		<div className={styles.container}>
			<img style={{ margin: 30 }} src={logoUrl} alt="logo" />
			<div>
				<div className={styles.imageContainer}>
					<img src={titleUrl} alt="title" />
				</div>

				<div className={styles.imageContainer}>
					<img src={step1Url} alt="step1" />
				</div>

				<div className={styles.imageContainer}>
					<img src={step2Url} alt="step2" />
				</div>

				<div className={styles.imageContainer}>
					<img src={step3Url} alt="step3" />
				</div>

				<div className={styles.imageContainer}>
					<img src={step4Url} alt="step4" />
				</div>
			</div>
			<NavButtonLink to="/" className={[buttonStyles.gray, buttonStyles.bold].join(" ")}>
				Cofnij
			</NavButtonLink>
		</div>
	);
};

export default MobileTutorial;
