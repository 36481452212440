import React from "react";
import NavButtonLink from "components/NavButtonLink";
import getOrderStatus from "components/orderStatus";
import PathsAPI from "constants/PathsAPI";
import stateMode from "constants/stateMode";
import { useFetch } from "hooks/useFetch";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styles from "styles/desktop/pages/Shop/UserOrders.module.css";
import Heading from "components/Heading";

const OrdersPage = () => {
	const { useGet } = useFetch(PathsAPI.UserOrders);
	const { response: ordersList, request: refreshOrdersList, loading } = useGet(true);
	const location = useLocation();

	const calcSummaryCost = (orderCart) => {
		let newSummary = 0;
		orderCart.forEach((product) => {
			const amount = product.hasOwnProperty("amount") ? product.amount : 1;
			newSummary += amount * product.brutto;
		});
		return newSummary.toFixed(2);
	};

	useEffect(() => {
		if (location.state === stateMode.Refresh) {
			refreshOrdersList();
			location.state = null;
		}
	}, [location, ordersList, refreshOrdersList]);

	const viewReceiveButton = (order) => {
		if (getOrderStatus(order).return) {
			return false;
		}

		return order.statusDelivery.length > 0 && !getOrderStatus(order).received > 0;
	};

	return (
		<div className={styles.container}>
			<div className={styles.content}>
				<Heading>Twoje zamówienia</Heading>
				<div className={styles.ordersContainer}>
					{ordersList && ordersList.length > 0 ? (
						ordersList.map((order, index) => (
							<div key={index} className={styles.order}>
								<div>
									<p className={styles.header}>Status</p>
									<p className={styles.value}>{getOrderStatus(order).message}</p>
								</div>
								<div>
									<p className={styles.header}>ID zamówienia</p>
									<p className={styles.value}>{order._id.substr(18)}</p>
								</div>
								<div>
									<p className={styles.header}>Zamówienie</p>
									<p className={styles.value}>
										{order.cartList.map((product) => `${product.name} ${product?.amount && `x${product.amount}`}`).join(", ")}
									</p>
								</div>
								<div>
									<p className={styles.header}>Data</p>
									<p className={styles.value}>{order.createdAt.slice(0, 10).split("-").reverse().join(".")}</p>
								</div>
								<div>
									<p className={styles.header}>Cena</p>
									<p className={styles.value}>{calcSummaryCost(order.cartList)}zł</p>
								</div>
								<div>
									{viewReceiveButton(order) && (
										<NavButtonLink
											className={styles.button}
											to={`pick-up/${order._id.substr(18)}`}
											state={{ order }}
											style={{ background: "var(--primary)", color: "white" }}
											disabled={order.received}
										>
											Otwórz skrytkę!
										</NavButtonLink>
									)}
								</div>
							</div>
						))
					) : loading ? (
						<h2>Ładowanie ..</h2>
					) : (
						<h2>Pusto tutaj ..</h2>
					)}
				</div>
			</div>
		</div>
	);
};

export default OrdersPage;
