import React from "react";
import logoUrl from "assets/mobile/logo.svg";
import mobileStyles from "styles/mobile/mobile.module.css";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import locale from "date-fns/locale/pl";
import { plPL } from "@mui/x-date-pickers";

import { useState } from "react";
import Button from "components/Button";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import buttonStyles from "styles/mobile/buttonStyles.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "hooks/useAuthContext";
import actionType from "constants/actionTypes";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";

const OrderDatePage = () => {
	const params = useParams();
	const navigate = useNavigate();
	const [openDateTimePicker, setOpenDateTimePicker] = useState(false);
	const [deliveryDate, setDeliveryDate] = useState(new Date());
	const { dispatch } = useAuthContext();

	let deliveryDateStart = new Date();
	let deliveryDateClose = new Date();
	deliveryDateClose.setDate(deliveryDateClose.getDate() + 7);

	const { useGet } = useFetch(`${PathsAPI.Suppliers}?device=${params.id}`);
	const { response: shopsInfo } = useGet(true);

	const handleChangeDeliveryDate = (value) => {
		setDeliveryDate(value);
	};

	const handleSaveOrderDate = () => {
		dispatch({ type: actionType.SET_ORDER_DATE, payload: deliveryDate });
		localStorage.removeItem("cart");
		navigate(`/dashboard/shop/online/products/${params.id}`);
	};

	const timeToTodayDeliveryCondition = deliveryDate.toDateString() === new Date().toDateString();

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
			}}
		>
			<img style={{ margin: "15px 30px" }} src={logoUrl} alt="logo" />

			<div
				style={{ width: "fit-content", margin: "0 auto", alignItems: "center" }}
				className={[mobileStyles.center, mobileStyles.spacing, mobileStyles.stretch].join(" ")}
			>
				<h1 style={{ textAlign: "center" }}>Na kiedy chcesz zamówić?</h1>

				<Button className={[buttonStyles.primary, buttonStyles.bold].join(" ")} onClick={handleSaveOrderDate}>
					Zatwierdź datę
				</Button>

				{shopsInfo && (
					<>
						<h3 style={{ textAlign: "center", padding: "0 48px" }}>
							{timeToTodayDeliveryCondition ? "Aby zamówienie zostało zrealizowane dziś, musi zostać złożone do godziny:" : "Ważne informacje:"}
						</h3>
						<ul>
							{shopsInfo.map((shopInfo) => (
								<li>
									{shopInfo.name},{" "}
									{timeToTodayDeliveryCondition ? (
										<>
											<strong>{shopInfo.maxOrdering}</strong>,
										</>
									) : (
										""
									)}{" "}
									<strong>*CNO: {shopInfo.maxReceiveTime}h</strong>, <strong>*MWK: {shopInfo.minCartCost} PLN</strong>
								</li>
							))}
						</ul>
						<h4>
							*CNO - maksymalny czas na obiór zamówienia, liczony od wybranego terminu dostawy w koszyku
							<br />
							*MWK - minimalna wartość koszyka, do realizacji zamówienia
						</h4>
					</>
				)}

				<div style={{ borderRadius: "1em" }}>
					<LocalizationProvider
						dateAdapter={AdapterDateFns}
						adapterLocale={locale}
						localeText={plPL.components.MuiLocalizationProvider.defaultProps.localeText}
					>
						<DateCalendar
							open={openDateTimePicker}
							onClose={() => {
								setOpenDateTimePicker(false);
							}}
							value={deliveryDate}
							onChange={handleChangeDeliveryDate}
							disablePast={true}
							minDate={deliveryDateStart}
							maxDate={deliveryDateClose}
							views={["day"]}
						/>
					</LocalizationProvider>
				</div>
			</div>
		</div>
	);
};

export default OrderDatePage;
