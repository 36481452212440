import React from "react";
import { useMap } from "react-leaflet";
import "./react-leaflet-geosearch.css";
import Button from "components/Button";
import buttonStyles from "styles/mobile/buttonStyles.module.css";

const SearchControl = (props) => {
	const map = useMap();

	return (
		<div style={{ position: "fixed", display: "flex", flexDirection: "column", bottom: "var(--navbar-height)", right: 15, zIndex: 1000, fontSize: 20 }}>
			<Button
				className={[buttonStyles.gray, buttonStyles.bold].join(" ")}
				style={{ marginBottom: 15, borderRadius: ".5rem" }}
				onClick={() => {
					map.zoomIn();
				}}
			>
				+
			</Button>
			<Button
				className={[buttonStyles.gray, buttonStyles.bold].join(" ")}
				style={{ marginBottom: 15, borderRadius: ".5rem" }}
				onClick={() => {
					map.zoomOut();
				}}
			>
				-
			</Button>
		</div>
	);
};
export default SearchControl;
