import React from "react";
import styles from 'styles/desktop/components/Heading/Heading.module.css'

const Heading = (props) => {
    const { children, ...other } = props;
    return (
        <h1 className={styles.heading} {...other} >{children}</h1>
    )
}

export default Heading