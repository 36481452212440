import React from "react";
import mobileStyles from "styles/mobile/mobile.module.css";
import stylesLogin from "styles/mobile/LoginPage.module.css";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import backgroundLogoUrl from "assets/mobile/backgroundLogo.svg";

const PasswordForget = () => {
	const { usePost } = useFetch(PathsAPI.ForgetPassword);
	const { request, response, hasError, loading } = usePost();

	const handleSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);

		await request(formData);
	};

	return (
		<>
			<div style={{ zIndex: 2, display: "flex", justifyContent: "center" }}>
				<form style={{ maxWidth: 300 }} className={[stylesLogin.form, mobileStyles.stretch].join(" ")} onSubmit={handleSubmit}>
					<input type="email" name="email" placeholder="e-mail" />

					<Button loading={loading}>Zresetuj hasło</Button>
					{response && <div className="info">{response.message}</div>}
					{hasError && <div className="error">{hasError.message}</div>}
					<NavButtonLink to="/">Powrót</NavButtonLink>
				</form>
			</div>
			<div className={stylesLogin.backgroundContainer}>
				<img
					style={{ top: 0, right: 0, transform: "translate(20%, -20%)" }}
					className={stylesLogin.backgroundLogo}
					src={backgroundLogoUrl}
					width={200}
					alt="logo"
				/>

				<img
					style={{ bottom: 0, right: 0, transform: "translate(20%, 20%)" }}
					className={stylesLogin.backgroundLogo}
					src={backgroundLogoUrl}
					width={200}
					alt="logo"
				/>

				<img
					style={{ bottom: 0, left: 0, transform: "translate(-30%, -10%)" }}
					className={stylesLogin.backgroundLogo}
					src={backgroundLogoUrl}
					width={200}
					alt="logo"
				/>
			</div>
		</>
	);
};

export default PasswordForget;
