import React from "react";
import { AttributionControl, MapContainer, TileLayer } from "react-leaflet";
import styles from "styles/mobile/Dashboard/User/Shop/MapPage.module.css";
import "styles/leaflet.css";
import { Outlet } from "react-router-dom";
import CustomMarkerShop from "components/leaflet/CustomMarkerShop";
import SearchControl from "components/leaflet/SearchControl";
import { OpenStreetMapProvider } from "react-leaflet-geosearch";

import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";

const MapPage = () => {
	const { useGet } = useFetch(`${PathsAPI.Devices}/user`);
	const { response } = useGet(true);
	const prov = OpenStreetMapProvider();

	return (
		<>
			<div className={styles.titleContainer}>
				<span>Wybierz urządzenie FoodBox</span>
			</div>
			<div className={styles.topBar} />
			<MapContainer
				attributionControl={false}
				center={[51.72513413988088, 17.399333257415663]}
				zoom={13}
				scrollWheelZoom={true}
				className={styles.mapContainer}
				zoomControl={false}
			>
				<TileLayer
					attribution='<a target="_blank" href="https://leafletjs.com/">Leaflet</a> | &copy; Autorzy <a target="_blank" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
					url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				<AttributionControl position="bottomright" prefix={false} />
				{response &&
					response.map((device, index) => {
						if (!device.coords) {
							return null;
						}
						return (
							<CustomMarkerShop
								key={index}
								open={device._id === "630bcaa4a99f2b06402cf75f"}
								position={[device.coords.lat, device.coords.lng]}
								device={{ id: device._id, location: device.locatedAt }}
							/>
						);
					})}

				<SearchControl provider={prov} />
			</MapContainer>
			<Outlet />
		</>
	);
};

export default MapPage;
