import React from "react";
import NavButtonLink from "components/NavButtonLink";

const SuccessPaymentOnlinePage = () => {
	localStorage.removeItem("cart");

	return (
		<>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					height: "calc(100vh - var(--navbar-height) - var(--navigation-height) - 60px)",
					justifyContent: "center",
					padding: "30px",
				}}
			>
				<h1 style={{ color: "var(--primary)" }}>
					<span style={{ fontStyle: "italic" }}>Dziękujemy</span> 😊
				</h1>
				<h2 style={{ textAlign: "center" }}>
					Twoja płatność została zakończona powodzeniem.
					<br />
					Dziękujemy za zakupy!
				</h2>
				<NavButtonLink style={{ background: "var(--primary)", boxShadow: "unset", color: "white" }} to="/">
					Powrót
				</NavButtonLink>
			</div>
		</>
	);
};

export default SuccessPaymentOnlinePage;
