import React from "react";

import { Outlet, useNavigate } from "react-router-dom";
import styles from "styles/UserDashboard.module.css";
import { useEffect } from "react";

const Dashboard = () => {
	let navigate = useNavigate();

	const refreshColorAccent = (redirectTo) => {
		document.documentElement.style.setProperty("--background-color", "white");
		document.documentElement.style.setProperty("--background-accent", "#e7e7e7");

		redirectTo && navigate(redirectTo);
	};

	useEffect(refreshColorAccent, [refreshColorAccent]);

	return (
		<>
			<div className={styles.container}>
				<Outlet />
			</div>
		</>
	);
};

export default Dashboard;
