class actionType {
	static LOGIN = "LOGIN";
	static LOGOUT = "LOGOUT";
	static SET_PAGETITLE = "SET_PAGETITLE";
	static OPEN_MENU = "OPEN_MENU";
	static CLOSE_MENU = "CLOSE_MENU";
	static SWITCH_MENU = "SWITCH_MENU";
	static VIEW_MENU = "VIEW_MENU";
	static SET_ORDER_DATE = "SET_ORDER_DATE";
}

export default actionType;
