import React from "react";
import PathsAPI from "constants/PathsAPI";
import { useFetch } from "hooks/useFetch";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "styles/desktop/pages/Shop/Categories.module.css";
import menuUrl from "assets/desktop/CategoriesPage/menu.svg";
import { useAuthContext } from "hooks/useAuthContext";

const CategoriesPage = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams();
	const [categoriesData, setCategoriesData] = useState();

	const { useGet } = useFetch(`${PathsAPI.Products}?device=${params.id}`);
	const { response: productsList } = useGet(true);

	const [expandShop, setExpandShop] = useState(false);

	const { useGet: useGetShop } = useFetch(`${PathsAPI.Suppliers}?device=${params.id}`);
	const { response: shopsInfo } = useGetShop(true);
	const { orderDate } = useAuthContext();

	useEffect(() => {
		if (!productsList || !orderDate || !shopsInfo) {
			return;
		}

		let categories = {};
		let suppliers = {};

		for (const product of productsList) {
			if (orderDate > new Date(product.endDeliveryDate)) {
				continue;
			}

			if (!categories[product.supplier]) {
				categories[product.supplier] = {};
			}

			categories[product.supplier][product.category] = { _id: { name: product.category, supplier: product.supplier } };

			const { _id, logoUrl, name } = product.shopName;
			suppliers[_id] = { _id, logoUrl, name };
		}

		suppliers = Object.values(suppliers);

		setCategoriesData({ categories, suppliers });
	}, [productsList, orderDate, shopsInfo]);

	useEffect(() => {
		location.state?.shop && setExpandShop(location.state.shop);
	}, [location.state?.shop]);

	const filterCategories = (categories, supplierId) => {
		return Object.values(categories[supplierId]);
	};

	const handleOpenCategory = (categoryData, supplier) => {
		const { name: category } = categoryData;
		const newPathname = location.pathname.replace("categories", "");
		navigate(newPathname, { state: { mode: "CATEGORY", name: category, shop: supplier } });
	};

	const handleExpandShop = (shopId) => {
		setExpandShop(shopId);
		handleOpenCategory({ name: "ALL" }, shopId);
	};

	const filteredSuppliers = () => {
		return categoriesData.suppliers.filter((supplier) => {
			return Object.values(categoriesData.categories?.[supplier._id] || []).length > 0;
		});
	};

	return (
		<div className={styles.container}>
			<img src={menuUrl} alt="menu" style={{ height: "5em", marginBottom: "2em" }} />

			<div className={styles.listContainer}>
				<div
					onClick={() => {
						handleOpenCategory({ name: "ALL" });
						setExpandShop(false);
					}}
					name="category"
				>
					Wszystkie produkty
				</div>
				<div
					onClick={() => {
						handleOpenCategory({ name: "VENDING" });
						setExpandShop(false);
					}}
					name="category"
				>
					Dostępne na miejscu
				</div>
				<div
					onClick={() => {
						handleOpenCategory({ name: "VOUCHERS" });
						setExpandShop(false);
					}}
					name="category"
				>
					Vouchery
				</div>

				{categoriesData?.suppliers ? (
					filteredSuppliers().map((supplier) => (
						<div key={supplier._id}>
							<div
								style={expandShop === supplier._id ? { background: "#3c3c3b" } : {}}
								name="categoryTitle"
								onClick={() => handleExpandShop(supplier._id)}
							>
								<img
									loading="lazy"
									height={"32px"}
									alt={`Logo sklepu - ${supplier.name}`}
									src={supplier.logoUrl}
									onError={(e) => {
										e.target.style.display = "none";
									}}
								></img>
								<b>{supplier.name}</b>
							</div>
							{expandShop === supplier._id &&
								categoriesData?.categories &&
								filterCategories(categoriesData.categories, supplier._id).map(({ _id: category }, index) => (
									<div
										key={index}
										style={location?.state?.name === category ? { background: "#3c3c3b" } : {}}
										onClick={() => {
											handleOpenCategory(category, supplier._id);
										}}
										name="category"
									>
										{category.name}
									</div>
								))}
						</div>
					))
				) : (
					<div>
						<p style={{ textAlign: "center" }}>Ładowanie ..</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default CategoriesPage;
