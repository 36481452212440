import React from "react";
import NavButtonIcon from "components/NavButtonIcon";
import { Outlet } from "react-router-dom";
import styles from "styles/mobile/Dashboard/User/UserDashboard.module.css";
import MenuIcon from "assets/mobile/Dashboard/menu.svg";
import MyAccountIcon from "assets/mobile/Dashboard/myAccount.svg";
import OrdersIcon from "assets/mobile/Dashboard/orders.svg";
import LogoutIcon from "assets/mobile/Dashboard/logout.svg";

const UserDashboard = () => {
	return (
		<>
			<div className={styles.container}>
				<Outlet />
			</div>
			<div className={styles.navigation}>
				<NavButtonIcon to="home" aria-label="Menu">
					<img src={MenuIcon} className={styles.navbarIcon} alt="menu" />
					<div>Menu</div>
				</NavButtonIcon>
				<NavButtonIcon to="user/account" aria-label="Moje konto">
					<img src={MyAccountIcon} className={styles.navbarIcon} alt="account" />
					<div>Moje konto</div>
				</NavButtonIcon>
				<NavButtonIcon to="user/account/orders" aria-label="Zamówienia">
					<img src={OrdersIcon} className={styles.navbarIcon} alt="online" />
					<div>Zamówienia</div>
				</NavButtonIcon>
				<NavButtonIcon to="/logout" aria-label="Wyloguj">
					<img src={LogoutIcon} className={styles.navbarIcon} alt="wyloguj" />
					<div>Wyloguj</div>
				</NavButtonIcon>
			</div>
		</>
	);
};

export default UserDashboard;
