import React from "react";
import logoUrl from "assets/mobile/logo.svg";
import bottomImageUrl from "assets/mobile/MenuPage/bottomImage.svg";
import NavButtonLink from "components/NavButtonLink";
import buttonStyles from "styles/mobile/buttonStyles.module.css";
import mobileStyles from "styles/mobile/mobile.module.css";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";

const MenuPage = () => {
	const { useGet } = useFetch(PathsAPI.UserLastOrderLocation);
	const { response: lastOrder, loading } = useGet(true);

	return (
		<>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					height: "50vh",
					justifyContent: "space-between",
					position: "fixed",
					top: 0,
					width: "100vw",
				}}
			>
				<img style={{ margin: "15px 30px" }} src={logoUrl} alt="logo" />

				<div style={{ width: "fit-content", margin: "0 auto" }} className={[mobileStyles.center, mobileStyles.spacing, mobileStyles.stretch].join(" ")}>
					{!loading && lastOrder?.deviceId && (
						<p style={{ textAlign: "center", fontWeight: 500 }}>
							Ostatnia lokalizacja: <br />
							{lastOrder ? lastOrder.location : ""}
						</p>
					)}

					<NavButtonLink disabled={!lastOrder?.deviceId} to={`/dashboard/shop/online/products/${lastOrder?.deviceId}/order-date`} className={[buttonStyles.primary, buttonStyles.bold].join(" ")}>
						Zrób zakupy
					</NavButtonLink>
					<NavButtonLink to="/dashboard/shop/online" className={[buttonStyles.gray, buttonStyles.bold].join(" ")}>
						Znajdź inną <br />
						lokalizację urządzenia
					</NavButtonLink>
				</div>
			</div>

			<img style={{ position: "fixed", bottom: 32, zIndex: -1, maxHeight: 400, width: "100%" }} src={bottomImageUrl} alt="bottomImage" />
		</>
	);
};

export default MenuPage;
