import React from "react";
import { useLogin } from "hooks/useLogin";
import styles from "styles/mobile/LoginPage.module.css";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import inputStyles from "styles/mobile/inputStyles.module.css";
import logoUrl from "assets/mobile/HomePageLogo.svg";
import mobileStyles from "styles/mobile/mobile.module.css";
import backgroundLogoUrl from "assets/mobile/backgroundLogo.svg";
import { Link } from "react-router-dom";

const MobileLogin = () => {
	const { login, error, isLoading } = useLogin();

	const handleSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);

		await login(formData.get("email"), formData.get("password"));
	};

	return (
		<>
			<div className={styles.container}>
				<img src={logoUrl} alt="logo" className={styles.logo}/>

				<form className={[styles.form, mobileStyles.stretch].join(" ")} onSubmit={handleSubmit}>
					<h1>Zaloguj się</h1>
					<input className={[inputStyles.gray, inputStyles.round].join(" ")} type="email" name="email" placeholder="e-mail" />

					<input
						className={[inputStyles.gray, inputStyles.round].join(" ")}
						type="password"
						name="password"
						placeholder="hasło"
						autoComplete="current-password"
					/>

					<Button className={styles.signInButton} loading={isLoading}>
						Zaloguj się
					</Button>
					{error && <div className="error">{error.message}</div>}
					<Link className={styles.forgotPasswordButton} to="/password/forget">
						Nie pamiętam hasła
					</Link>
					<NavButtonLink className={styles.returnButton} to="/">
						Powrót
					</NavButtonLink>
				</form>
			</div>
			<div className={styles.backgroundContainer}>
				<img
					style={{ top: 0, right: 0, transform: "translate(20%, -20%)" }}
					className={styles.backgroundLogo}
					src={backgroundLogoUrl}
					width={200}
					alt="logo"
				/>

				<img
					style={{ bottom: 0, right: 0, transform: "translate(20%, 20%)" }}
					className={styles.backgroundLogo}
					src={backgroundLogoUrl}
					width={200}
					alt="logo"
				/>

				<img
					style={{ bottom: 0, left: 0, transform: "translate(-30%, -10%)" }}
					className={styles.backgroundLogo}
					src={backgroundLogoUrl}
					width={200}
					alt="logo"
				/>
			</div>
		</>
	);
};

export default MobileLogin;
