import React from "react";
import Button from "components/Button";
import Heading from "components/Heading";
import NavButtonLink from "components/NavButtonLink";
import PathsAPI from "constants/PathsAPI";
import stateMode from "constants/stateMode";
import { useFetch } from "hooks/useFetch";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "styles/desktop/pages/Shop/PickupPage.module.css";

const PickUpPage = () => {
	const location = useLocation();
	const order = location?.state?.order;
	const { usePut } = useFetch(PathsAPI.UserOrderPickUp);
	const { request, hasError } = usePut();
	const [loading, setLoading] = useState({});

	const handlePickUpOrder = async (deliveryId) => {
		try {
			setLoading({ ...loading, [deliveryId]: true });
			const res = await request({ orderId: order._id, deliveryId });
			res.status && toast.success("Twoja skrytka została otwarta!");
			setLoading({ ...loading, [deliveryId]: false });
		} catch (error) {
			toast.error(error);
			setLoading({ ...loading, [deliveryId]: false });
		}
	};

	return (
		<>
			{order && (
				<div className={styles.container}>
					<Heading>Odbierz swoje zamówienie</Heading>
					<div className={styles.content}>
						<h1>
							<span className={styles.title}>Numer zamówienia:</span> {order._id.substr(18)}
						</h1>
						<h1>
							<span className={styles.title}>Lokalizacja urządzenia:</span> {order.device.location}
						</h1>
						<div className={styles.buttonActions}>
							{order.statusDelivery.map((partDelivery) => (
								<Button
									className={styles.pickUp}
									onClick={() => {
										handlePickUpOrder(partDelivery.deliveryId);
									}}
									loading={loading[partDelivery.deliveryId]}
									error={hasError}
								>
									Odbierz (Skrytka {partDelivery.boxId})
								</Button>
							))}

							<NavButtonLink to="/dashboard/user/account/orders" state={stateMode.Refresh}>
								Powrót
							</NavButtonLink>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default PickUpPage;
