import React from "react";
import PathsAPI from "constants/PathsAPI";
import { useFetch } from "hooks/useFetch";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "styles/mobile/Dashboard/User/Shop/CategoriesPage.module.css";
import backToProductsUrl from "assets/mobile/ProductsPage/backToProducts.svg";
import Icon from "@mdi/react";
import { mdiMinus, mdiPlus } from "@mdi/js";
import { useAuthContext } from "hooks/useAuthContext";

const CategoriesPage = ({ returnSearchText }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams();
	const [searchCategory, setSearchText] = useState("");

	const [categoriesData, setCategoriesData] = useState();
	const { useGet } = useFetch(`${PathsAPI.Products}?device=${params.id}`);
	const { response: productsList } = useGet(true);

	const { useGet: useGetShop } = useFetch(`${PathsAPI.Suppliers}?device=${params.id}`);
	const { response: shopsInfo } = useGetShop(true);
	const { orderDate } = useAuthContext();

	const [expandShop, setExpandShop] = useState("");

	useEffect(() => {
		if (!productsList || !orderDate || !shopsInfo) {
			return;
		}

		let categories = {};
		let suppliers = {};

		for (const product of productsList) {
			if (orderDate > new Date(product.endDeliveryDate)) {
				continue;
			}

			if (!categories[product.supplier]) {
				categories[product.supplier] = {};
			}

			categories[product.supplier][product.category] = { _id: { name: product.category, supplier: product.supplier } };

			const { _id, logoUrl, name } = product.shopName;
			suppliers[_id] = { _id, logoUrl, name };
		}

		suppliers = Object.values(suppliers);

		setCategoriesData({ categories, suppliers });
	}, [productsList, orderDate, shopsInfo]);

	const handleChangeSearchText = (e) => {
		setSearchText(e.target.value);
		returnSearchText && returnSearchText(e.target.value);
	};

	const filterCategories = (categories, supplierId) => {
		return Object.values(categories[supplierId]).filter(({ _id: category }) => {
			if (searchCategory === "") {
				return true;
			}

			return category.name.toLowerCase().includes(searchCategory.toLowerCase());
		});
	};

	const handleOpenCategory = (categoryData, supplier) => {
		const { name: category } = categoryData;
		const newPathname = location.pathname.replace("categories", "");
		navigate(newPathname, { state: { mode: "CATEGORY", name: category, shop: supplier } });
	};

	const handleExpandShop = (shopId) => {
		setExpandShop(expandShop === shopId ? "" : shopId);
	};

	const filteredSuppliers = () => {
		return categoriesData.suppliers.filter((supplier) => {
			return Object.values(categoriesData.categories?.[supplier._id] || []).length > 0;
		});
	};

	const handleBack = () => {
		const newPathname = location.pathname.replace("categories", "");
		navigate(newPathname, { state: location.state });
	};

	return (
		<div className={styles.container}>
			<img style={{ height: "3rem", margin: "auto", marginLeft: 0 }} src={backToProductsUrl} alt="cofnij" onClick={handleBack} />
			<h1>Menu</h1>
			<input onChange={handleChangeSearchText} value={searchCategory} placeholder="Na co masz ochotę?" />
			<div className={styles.listContainer}>
				<div
					onClick={() => {
						handleOpenCategory({ name: "ALL" });
					}}
					name="category"
				>
					Wszystkie produkty
				</div>
				<div
					onClick={() => {
						handleOpenCategory({ name: "VENDING" });
					}}
					name="category"
				>
					Dostępne na miejscu
				</div>
				<div
					onClick={() => {
						handleOpenCategory({ name: "VOUCHERS" });
						setExpandShop(false);
					}}
					name="category"
				>
					Vouchery
				</div>

				{categoriesData?.suppliers ? (
					filteredSuppliers().map((supplier) => (
						<div key={supplier._id}>
							<div name="categoryTitle" onClick={() => handleExpandShop(supplier._id)}>
								<div style={{ display: "flex", alignItems: "center" }}>
									<img
										loading="lazy"
										height={"32px"}
										alt={`Logo sklepu - ${supplier.name}`}
										src={supplier.logoUrl}
										onError={(e) => {
											e.target.style.display = "none";
										}}
										style={{ borderRadius: "4px", aspectRatio: "1/1", marginRight: 4 }}
									></img>
									<b>{supplier.name}</b>
								</div>
								<Icon
									style={{ borderRadius: "1em", background: "var(--primary)" }}
									path={expandShop === supplier._id ? mdiMinus : mdiPlus}
									size={1}
								/>
							</div>
							{expandShop === supplier._id &&
								categoriesData?.categories &&
								filterCategories(categoriesData.categories, supplier._id).map(({ _id: category }, index) => (
									<div
										key={index}
										style={location?.state?.name === category ? { background: "#e3ffc2" } : {}}
										onClick={() => {
											handleOpenCategory(category, supplier._id);
										}}
										name="category"
									>
										{category.name}
									</div>
								))}
						</div>
					))
				) : (
					<div>
						<p style={{ textAlign: "center" }}>Ładowanie ..</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default CategoriesPage;
