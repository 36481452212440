import React from "react";

import CardDetailsPage from "pages/Dashboard/User/CardDetailsPage";

import { Navigate, Route } from "react-router-dom";

import UserDashboard from "pages/Dashboard/User";
import HomePage from "pages/Dashboard/User/HomePage";
import AccountPage from "pages/Dashboard/User/AccountPage";
import OnlinePage from "pages/Dashboard/User/Shop/Online";
import ProductsPage from "pages/Dashboard/User/Shop/Online/ProductsPage";
import DescriptionPage from "pages/Dashboard/User/Shop/Online/DescriptionPage";
import CategoriesPage from "pages/Dashboard/User/Shop/Online/CategoriesPage";
import CartPage from "pages/Dashboard/User/Shop/Online/CartPage";
// import SelectShopPage from "pages/Dashboard/User/Shop/Online/SelectShopPage";
// import ScanProducts from "pages/Dashboard/User/Shop/Store/ScanProducts";
import SummaryPage from "pages/Dashboard/User/Shop/Online/SummaryPage";
import OrdersPage from "pages/Dashboard/User/Orders";
import PickUpPage from "pages/Dashboard/User/Orders/PickUpPage";
// import StoreCartPage from "pages/Dashboard/User/Shop/Store/StoreCartPage";
// import StoreSummaryPage from "pages/Dashboard/User/Shop/Store/StoreSummaryPage";
import ReturnPaymentOnlinePage from "pages/Dashboard/User/Shop/Online/Pay/ReturnPaymentOnlinePage";
import SuccessPaymentOnlinePage from "pages/Dashboard/User/Shop/Online/Pay/SuccessPaymentOnlinePage";
import FailurePaymentOnlinePage from "pages/Dashboard/User/Shop/Online/Pay/FailurePaymentOnlinePage";
import OrderDatePage from "pages/Dashboard/User/Shop/Online/OrderDatePage";
import TutorialPage from "pages/Tutorial";

import Helpdesk from "pages/Dashboard/User/Helpdesk";
import PasswordChange from "pages/Dashboard/User/PasswordChange.js";

const UserRoutes = () => {
	return (
		<>
			<Route path="dashboard" element={<UserDashboard />}>
				<Route index element={<Navigate to="home" />} />
				<Route path="home" element={<HomePage />} />
				<Route path="tutorial" element={<TutorialPage />} />
				<Route path="helpdesk" element={<Helpdesk />} />
				<Route path="user/account" element={<AccountPage />} />
				<Route path="password/change" element={<PasswordChange />} />
				<Route path="user/account/orders" element={<OrdersPage />}></Route>
				<Route path="user/account/orders/pick-up/:id" element={<PickUpPage />} />
				<Route path="user/account/card-details" element={<CardDetailsPage />} />
				<Route path="shop/online" element={<OnlinePage />}>
					{/* <Route path="select-shop" element={<SelectShopPage />} /> */}
				</Route>
				<Route path="shop/online/products/:id" element={<ProductsPage />}>
					<Route path="desc" element={<DescriptionPage />} />
				</Route>
				<Route path="shop/online/products/:id/categories" element={<CategoriesPage />} />
				<Route path="shop/online/products/:id/cart" element={<CartPage />} />
				<Route path="shop/online/products/:id/cart/summary" element={<SummaryPage />} />
				<Route path="shop/online/products/:id/order-date" element={<OrderDatePage />} />

				<Route path="shop/online/pay/return/:token" element={<ReturnPaymentOnlinePage />} />
				<Route path="shop/online/pay/success/:token" element={<SuccessPaymentOnlinePage />} />
				<Route path="shop/online/pay/failure/:token" element={<FailurePaymentOnlinePage />} />

				{/* <Route path="shop/store/cart" element={<StoreCartPage />} />
				<Route path="shop/store/cart/summary" element={<StoreSummaryPage />} /> */}
			</Route>
			{/* <Route path="dashboard/shop/store" element={<ScanProducts />} /> */}
		</>
	);
};

export default UserRoutes;
