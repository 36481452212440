import React from "react";
import AvailabilityChecker from "pages/mobile/Dashboard/User/Shop/checkAvailability";

class FilterProducts {
	constructor(productsList, kioskProductsList, orderDate) {
		this.productsList = productsList;
		this.kioskProductsList = kioskProductsList;
		this.orderDate = orderDate;
	}

	filter = (filterCategory, filterShop, filterTextProducts = false) => {
		const { productsList, kioskProductsList, orderDate } = this;

		if (filterCategory === "VENDING" && kioskProductsList) {
			const newFilteredProducts = productsList.filter((product) => {
				return kioskProductsList.find((kioskProductId) => {
					return kioskProductId === product._id;
				});
			});

			return newFilteredProducts;
		}

		const newFilteredProducts = productsList.filter((product) => {
			if (!product.hasOwnProperty("startDeliveryDate") || !product.hasOwnProperty("endDeliveryDate")) {
				return false;
			}

			if (orderDate > new Date(product.endDeliveryDate)) {
				return false;
			}

			let categoryCondition = false;

			if (filterCategory === "ALL") {
				categoryCondition = true;
			} else {
				categoryCondition = product.category === filterCategory;
			}

			if (filterShop) {
				categoryCondition = categoryCondition && product.supplier === filterShop;
			}

			if (!categoryCondition) {
				return false;
			}

			if (!filterTextProducts || filterTextProducts.length === 0) {
				return true;
			}

			let nameCondition = [];
			let descCondition = [];

			for (const partName of filterTextProducts.split(" ")) {
				if (partName.length === 0) {
					continue;
				}

				const condition = product.name.toLowerCase().includes(partName.toLowerCase());
				nameCondition.push(condition);
			}

			for (const partDesc of filterTextProducts.split(" ")) {
				if (partDesc.length === 0) {
					continue;
				}

				const condition = product.desc.toLowerCase().includes(partDesc.toLowerCase());
				descCondition.push(condition);
			}

			if (!nameCondition.every((element) => element === true) && !descCondition.every((element) => element === true)) {
				return false;
			}

			return true;
		});

		const isKioskAvailableProduct = (productId) => {
			return (
				kioskProductsList.find((kioskProductId) => {
					return kioskProductId === productId;
				}) || false
			);
		};

		const availabilityChecker = new AvailabilityChecker(orderDate);

		let priorityProducts = newFilteredProducts.filter((product) => {
			const inKiosk = isKioskAvailableProduct(product._id);
			const isAvailable = availabilityChecker.checkAvailability(product) === true;

			return inKiosk || isAvailable;
		});

		let normalProducts = newFilteredProducts.filter((product) => {
			const notIsKiosk = !isKioskAvailableProduct(product._id);
			const isNotAvailable = availabilityChecker.checkAvailability(product) !== true;

			return notIsKiosk & isNotAvailable;
		});

		priorityProducts.sort((high, low) => {
			const up = -1;
			const down = 1;

			if (new Date(high.createdAt).getTime() > new Date(low.createdAt).getTime()) {
				return up;
			} else if (new Date(high.createdAt).getTime() < new Date(low.createdAt).getTime()) {
				return down;
			} else {
				return 0;
			}
		});

		normalProducts.sort((high, low) => {
			const up = -1;
			const down = 1;

			if (new Date(high.createdAt).getTime() > new Date(low.createdAt).getTime()) {
				return up;
			} else if (new Date(high.createdAt).getTime() < new Date(low.createdAt).getTime()) {
				return down;
			} else {
				return 0;
			}
		});

		return [...priorityProducts, ...normalProducts];
	};
}

export default FilterProducts;
