import React from "react";
import NavButtonLink from "components/NavButtonLink";

const SummaryPage = () => {
	return (
		<>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					height: "calc(100vh - var(--navbar-height) - var(--navigation-height) - 60px)",
					justifyContent: "center",
					padding: "30px",
				}}
			>
				<h1 style={{ color: "var(--primary)", fontStyle: "italic" }}>Dziękujemy!</h1>
				<h2 style={{ textAlign: "center", fontWeight: "100" }}>Twoje zamówienie dostarczymy do FoodBox w wyznaczonym terminie.</h2>
				<NavButtonLink style={{ background: "var(--primary)", boxShadow: "unset", color: "white" }} to="/">
					Powrót
				</NavButtonLink>
			</div>
		</>
	);
};

export default SummaryPage;
