import React from "react";
import NavButtonLink from "components/NavButtonLink";
import { useLocation } from "react-router-dom";
import styles from "styles/DescriptionPage.module.css";
import parse from "html-react-parser";

const DescriptionPage = () => {
	const location = useLocation();
	const product = location?.state?.product;

	const getLangPrefBoxType = (prefBoxType) => {
		switch (prefBoxType) {
			case "COLD":
				return "Chłodna skrytka";
			case "WARM":
				return "Ciepła skrytka";
			default:
				return "";
		}
	};

	// TODO - nowe linie

	return (
		<>
			{product && (
				<div className={styles.container}>
					<img loading="lazy" alt={product.name} src={product.photoUrl} className={styles.photoDesc}></img>
					<h2>{product.name}</h2>
					<h3>{getLangPrefBoxType(product.prefBoxType)}</h3>
					<h4>Opis produktu</h4>
					<div className={styles.shopLocationTitle}>{parse(product.desc.replaceAll("\n", "<br/>"))}</div>
					<NavButtonLink to="..">Powrót</NavButtonLink>
				</div>
			)}
		</>
	);
};

export default DescriptionPage;
