import React from "react";
import mobileStyles from "styles/mobile/mobile.module.css";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import locale from "date-fns/locale/pl";
import { plPL } from "@mui/x-date-pickers";

import { useEffect, useState } from "react";
import Button from "components/Button";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import buttonStyles from "styles/mobile/buttonStyles.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "hooks/useAuthContext";
import actionType from "constants/actionTypes";

import layoutStyles from "styles/OrderDatePageLayout.module.css";
import Heading from "components/Heading";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";

const OrderDatePage = () => {
	const params = useParams();
	const navigate = useNavigate();
	const [openDateTimePicker, setOpenDateTimePicker] = useState(false);
	const [deliveryDate, setDeliveryDate] = useState(new Date());
	const { dispatch } = useAuthContext();

	const [deliveryDateStart, setDeliveryDateStart] = useState(false);
	const [deliveryDateClose, setDeliveryDateClose] = useState(false);

	const { useGet } = useFetch(`${PathsAPI.Suppliers}?device=${params.id}`);
	const { response: shopsInfo, serverDate } = useGet(true);

	useEffect(() => {
		if (!serverDate) {
			return;
		}

		setDeliveryDateStart(new Date(serverDate));
		let tempDeliveryDateClose = new Date(serverDate);
		tempDeliveryDateClose.setDate(tempDeliveryDateClose.getDate() + 7);
		setDeliveryDateClose(tempDeliveryDateClose);
	}, [serverDate]);

	const handleChangeDeliveryDate = (value) => {
		setDeliveryDate(value);
	};

	const handleSaveOrderDate = () => {
		dispatch({ type: actionType.SET_ORDER_DATE, payload: deliveryDate });
		localStorage.removeItem("cart");
		navigate(`/dashboard/shop/online/products/${params.id}`);
	};

	const timeToTodayDeliveryCondition = deliveryDate.toDateString() === new Date().toDateString();

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				height: "var(--full-page-height)",
			}}
		>
			<div
				style={{ width: "fit-content", margin: "auto", alignItems: "center" }}
				className={[mobileStyles.center, mobileStyles.spacing, mobileStyles.stretch].join(" ")}
			>
				<Heading>Na kiedy chcesz zamówić?</Heading>

				<p className={layoutStyles.noticePrompt} style={{ textAlign: "center" }}>
					Wybierz termin oraz godzinę odbioru dania!
				</p>

				<Button className={[buttonStyles.primary, buttonStyles.bold, layoutStyles.roundedButton].join(" ")} onClick={handleSaveOrderDate}>
					Wybierz datę
				</Button>

				{shopsInfo && (
					<>
						<h3 style={{ textAlign: "center", padding: "0 48px" }}>
							{timeToTodayDeliveryCondition ? "Aby zamówienie zostało zrealizowane dziś, musi zostać złożone do godziny:" : "Ważne informacje:"}
						</h3>
						<ul>
							{shopsInfo.map((shopInfo, index) => (
								<li key={index}>
									{shopInfo.name},{" "}
									{timeToTodayDeliveryCondition ? (
										<>
											<strong>{shopInfo.maxOrdering}</strong>,
										</>
									) : (
										""
									)}{" "}
									<strong>*CNO: {shopInfo.maxReceiveTime}h</strong>, <strong>*MWK: {shopInfo.minCartCost} PLN</strong>
								</li>
							))}
						</ul>
						<h4>
							*CNO - maksymalny czas na obiór zamówienia, liczony od wybranego terminu dostawy w koszyku
							<br />
							*MWK - minimalna wartość koszyka, do realizacji zamówienia
						</h4>
					</>
				)}

				<div className={layoutStyles.calendar} style={{ borderRadius: "1em" }}>
					<LocalizationProvider
						dateAdapter={AdapterDateFns}
						adapterLocale={locale}
						localeText={plPL.components.MuiLocalizationProvider.defaultProps.localeText}
					>
						<DateCalendar
							open={openDateTimePicker}
							onClose={() => {
								setOpenDateTimePicker(false);
							}}
							value={deliveryDate}
							onChange={handleChangeDeliveryDate}
							disablePast={true}
							minDate={deliveryDateStart}
							maxDate={deliveryDateClose}
							views={["day"]}
							disabled={!deliveryDateStart || !deliveryDateClose}
						/>
					</LocalizationProvider>
				</div>
			</div>
		</div>
	);
};

export default OrderDatePage;
