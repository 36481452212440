import React from "react";
import styles from "styles/mobile/Layout.module.css";
import { Outlet } from "react-router-dom";

const MobileLayout = () => {
	return (
		<div className={styles.container}>
			<Outlet />
		</div>
	);
};

export default MobileLayout;
