import React from "react";
import CardDetailsPage from "pages/Dashboard/User/CardDetailsPage";

import { Navigate, Route } from "react-router-dom";

import UserDashboard from "pages/mobile/Dashboard/User/UserDashboard";
import MenuPage from "pages/mobile/Dashboard/User/MenuPage";
import AccountPage from "pages/mobile/Dashboard/User/AccountPage";
import MapPage from "pages/mobile/Dashboard/User/Shop/MapPage";
import ProductsPage from "pages/mobile/Dashboard/User/Shop/ProductsPage";

import CategoriesPage from "pages/mobile/Dashboard/User/Shop/CategoriesPage";
import CartPage from "pages/mobile/Dashboard/User/Shop/CartPage";

import OrderDatePage from "pages/mobile/Dashboard/User/Shop/OrderDatePage";

import SummaryPage from "pages/Dashboard/User/Shop/Online/SummaryPage";
import OrdersPage from "pages/mobile/Dashboard/User/Orders";
import PickUpPage from "pages/mobile/Dashboard/User/Orders/PickUpPage";

import ReturnPaymentOnlinePage from "pages/Dashboard/User/Shop/Online/Pay/ReturnPaymentOnlinePage";
import SuccessPaymentOnlinePage from "pages/Dashboard/User/Shop/Online/Pay/SuccessPaymentOnlinePage";
import FailurePaymentOnlinePage from "pages/Dashboard/User/Shop/Online/Pay/FailurePaymentOnlinePage";

import PasswordChange from "pages/mobile/PasswordChange.js";
import HelpdeskMobile from "pages/mobile/Dashboard/HelpDeskMobile";

const MobileUserRoutes = () => {
	return (
		<>
			<Route path="dashboard" element={<UserDashboard />}>
				<Route index element={<Navigate to="home" />} />
				<Route path="home" element={<MenuPage />} />
				<Route path="user/account" element={<AccountPage />} />
				<Route path="user/account/orders" element={<OrdersPage />}>
					<Route path="pick-up/:id" element={<PickUpPage />} />
				</Route>
				<Route path="password/change" element={<PasswordChange />} />
				<Route path="user/account/card-details" element={<CardDetailsPage />} />
				<Route path="shop/online" element={<MapPage />} />

				<Route path="shop/online/products/:id" element={<ProductsPage />}></Route>

				<Route path="shop/online/products/:id/categories" element={<CategoriesPage />} />
				<Route path="shop/online/products/:id/cart" element={<CartPage />} />
				<Route path="shop/online/products/:id/cart/summary" element={<SummaryPage />} />
				<Route path="shop/online/products/:id/order-date" element={<OrderDatePage />} />
				<Route path="helpdesk" element={<HelpdeskMobile />} />
				<Route path="shop/online/pay/return/:token" element={<ReturnPaymentOnlinePage />} />
				<Route path="shop/online/pay/success/:token" element={<SuccessPaymentOnlinePage />} />
				<Route path="shop/online/pay/failure/:token" element={<FailurePaymentOnlinePage />} />
			</Route>
		</>
	);
};

export default MobileUserRoutes;
