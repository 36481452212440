import React from "react";
import iconUrl from "assets/marker-icon.png";
import iconShadowUrl from "leaflet/dist/images/marker-shadow.png";
import { icon } from "leaflet";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Marker, Popup } from "react-leaflet";
import styles from "styles/CustomMarkerShop.module.css";
import Button from "components/Button";

let blockCloseWindow = false;

const CustomMarkerShop = ({ position, device, open }) => {
	let navigate = useNavigate();
	const [closeSelectShop, setCloseSelectShop] = useState(false);
	const markerRef = useRef(null);

	useEffect(() => {
		const marker = markerRef.current;
		if (marker && open) {
			marker.openPopup();
		}
	}, [markerRef, open]);

	useEffect(() => {
		closeSelectShop && !blockCloseWindow && navigate(".");
		blockCloseWindow = false;
		setCloseSelectShop(false);
	}, [closeSelectShop, navigate, setCloseSelectShop]);

	const handleOpenShop = () => {
		navigate(`products/${device.id}/order-date`);
	};

	return (
		<Marker
			ref={markerRef}
			position={position}
			icon={icon({
				iconUrl: iconUrl,
				shadowUrl: iconShadowUrl,
				iconSize: [25, 41],
				iconAnchor: [13, 40],
			})}
			eventHandlers={{
				popupopen() {
					blockCloseWindow = true;
				},
				popupclose() {
					setCloseSelectShop(true);
				},
			}}
		>
			<Popup className={styles.popupContainer} closeButton={false}>
				<p>{device.location}</p>
				<Button
					className={styles.selectButton}
					onClick={() => {
						handleOpenShop();
					}}
				>
					Zamawiam tu!
				</Button>
			</Popup>
		</Marker>
	);
};

export default CustomMarkerShop;
