import React from "react";
import styles from "styles/desktop/pages/Tutorial/Tutorial.module.css"
import dish1Url from "assets/desktop/TutorialPage/dish1.png";
import dish2Url from "assets/desktop/TutorialPage/dish2.png";
import { ReactComponent as DashedLineImage } from 'assets/desktop/TutorialPage/dashed-line.svg'
import iconStep1 from 'assets/icons/icon-localization.svg'
import iconStep2 from 'assets/icons/icon-cart.svg'
import iconStep3 from 'assets/icons/icon-truck.svg'
import iconStep4 from 'assets/icons/icon-box.svg'
import Heading from "components/Heading";
const TutorialPage = () => {
    return (
        <div className={styles.root}>
            <div className={styles.content}>
                <Heading>Jak zamawiać?</Heading>
                <div className={styles.tutorialStep}>
                    <img src={iconStep1} alt="step1icon"/>
                    Znajdź urządzenie FoodBox do odbioru zamówień
                </div>
                <div className={styles.tutorialStep}>
                    <img src={iconStep2} alt="step2icon"/>
                    Dodaj produkty do koszyka i zapłać w aplikacji
                </div>
                <div className={styles.tutorialStep}>
                    <img src={iconStep3} alt="step3icon"/>
                    Gotowe zamówienie dostarczymy do wybranego przez Ciebie FoodBox
                </div>
                <div className={styles.tutorialStep}>
                    <img src={iconStep4} alt="step4icon"/>
                    Odbierz zamówienie na miejscu przechodząc do zakładki zamówienia
                </div>

            </div>

            <div className={styles.backgroundItems}>
                <img src={dish1Url} alt="pizza" style={{
                    bottom: "9vh",
                    right: "-6vw",
                    height: '50vh',
                    transform: "rotate(200deg) scale(0.8)"
                }} />
                <img src={dish2Url} alt="miska" style={{
                    bottom: "10vh",
                    left: "-4vw",
                    height: '40vh',
                }} />
                <DashedLineImage style={{
                    bottom: '5vh',
                    zIndex: -1
                }}/>
            </div>
        </div>
    )
}

export default TutorialPage;