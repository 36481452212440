import React from "react";
import styles from "styles/desktop/pages/HomePage/HomePage.module.css";
import Heading from "components/Heading";
import IconLocalisation from "assets/icons/icon-localization.svg";
import IconCart from "assets/icons/icon-cart.svg";
import { ReactComponent as DashedLineLeft } from "assets/desktop/HomePage/dashed-line-left.svg";
import { ReactComponent as DashedLineRight } from "assets/desktop/HomePage/dashed-line-right.svg";
import Input from "components/Form/Input";
import NavButtonLink from "components/NavButtonLink";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PathsAPI from "constants/PathsAPI";
import { useFetch } from "hooks/useFetch";
const HomePage = () => {
	const [cityName, setCityName] = useState("");
	const navigate = useNavigate();

	const { useGet } = useFetch(PathsAPI.UserLastOrderLocation);
	const { response: lastOrder, loading } = useGet(true);


	return (
		<div className={styles.container}>
			<div className={styles.background}>
				<DashedLineLeft
					style={{
						bottom: "-75vh",
						left: "-18vw",
						height: "70vh",
					}}
				/>
				<DashedLineRight
					style={{
						bottom: "-100vh",
						right: "-15vw",
						height: "70vh",
					}}
				/>
			</div>
			<Heading>Zamów teraz!</Heading>
			<h1 className={styles.subtitle}>Zamów do skrytek w najbliższej lokalizacji lub znajdź nową!</h1>
			<div className={styles.widgetContainer}>
				{!loading && lastOrder?.deviceId && (
					<div className={styles.widget}>
						<img src={IconCart} alt="localisation" />
						<div className={styles.widgetContent}>
							<p>
								To urządzenie do odbioru jest <strong>NAJBLIŻEJ CIEBIE</strong>!
							</p>
							<Heading style={{ fontSize: "2em", margin: 0 }}>{lastOrder ? lastOrder.location : ""}</Heading>
						</div>
						<NavButtonLink disabled={!lastOrder?.deviceId} to={`/dashboard/shop/online/products/${lastOrder?.deviceId}/order-date`} className={styles.widgetButton}>
							<strong>Kliknij i zamów</strong> do wybranej lokalizacji!
						</NavButtonLink>
					</div>
				)}
				<div className={styles.widget}>
					<img src={IconLocalisation} alt="localisation" />
					<div className={styles.widgetContent}>
						<p>
							Chcesz odebrać w innej lokalizacji? <strong>NIE MA SPRAWY</strong>!
						</p>
						<Input
							placeholder="Wpisz miasto"
							value={cityName}
							onChange={(e) => setCityName(e.target.value)}
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									navigate("/dashboard/shop/online", { state: { searchCity: cityName } });
								}
							}}
						/>
					</div>
					<NavButtonLink
						to="/dashboard/shop/online"
						state={{
							searchCity: cityName,
						}}
						className={styles.widgetButton}
					>
						<strong>Znajdź inną lokalizację </strong> urządzenia FoodBox
					</NavButtonLink>
				</div>
			</div>
		</div>
	);
};

export default HomePage;
