import React from "react";
import { AttributionControl, MapContainer, TileLayer, useMap } from "react-leaflet";
import styles from "styles/desktop/pages/OnlinePage/MapPage/MapPage.module.css";
import "styles/leaflet.css";
import { useLocation } from "react-router-dom";
import CustomMarkerShop from "components/leaflet/CustomMarkerShop";
import SearchControl from "components/leaflet/SearchControl";
import { OpenStreetMapProvider } from "react-leaflet-geosearch";
import CustomMarkerSearch from "components/leaflet/CustomMarkerSearch";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import { useEffect, useState } from "react";
import Heading from "components/Heading";
import NavButtonLink from "components/NavButtonLink";
import { ReactComponent as SearchIcon } from "assets/icons/icon-search.svg";
import { useNavigate } from "react-router-dom";
const StorePage = () => {
	const { useGet } = useFetch(`${PathsAPI.Devices}/user`);
	const { response } = useGet(true);
	const prov = OpenStreetMapProvider();
	const [centerCoords, setCenterCoords] = useState(null);
	const [searchText, setSearchText] = useState("");
	let navigate = useNavigate();
	const location = useLocation();

	const SetViewOnClick = ({ coords }) => {
		const map = useMap();
		if (coords === null) return null;
		map.setView(coords, map.getZoom());

		return null;
	};

	useEffect(() => {
		if (location?.state?.searchCity) {
			fetch(`https://nominatim.openstreetmap.org/search?q=${location.state.searchCity}&format=json`)
				.then((res) => res.json())
				.then((rsp) => {
					setCenterCoords([parseFloat(rsp[0].lat), parseFloat(rsp[0].lon)]);
				});
		} else {
			setCenterCoords([51.72513413988088, 17.399333257415663]);
		}
	}, [location]);

	const handleOpenShop = (device) => {
		navigate(`products/${device.id}/order-date`);
	};

	return (
		<div className={styles.container}>
			<Heading style={{ fontSize: "3em" }}>Gdzie chcesz odebrać zamówienie?</Heading>
			<div className={styles.content}>
				<div className={styles.pickupPointsListContainer}>
					<div className={styles.searchBar}>
						<SearchIcon />
						<input type="text" placeholder="Wpisz miasto" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
					</div>
					<div className={styles.pickupPointsList}>
						<div className={styles.pickupPointsListContent}>
							{response ? (
								response
									.filter((device) => {
										if (searchText === "") return true;
										return device?.locatedAt?.toLowerCase().includes(searchText.toLowerCase());
									})
									.map((device, index) => {
										if (!device.coords) {
											return null;
										}
										return (
											<div
												key={index}
												className={styles.pickupPoint}
												onClick={() => setCenterCoords([device.coords.lat, device.coords.lng])}
											>
												<p>{device.locatedAt}</p>
												<NavButtonLink onClick={() => handleOpenShop({ id: device._id, location: device.locatedAt })}>
													Zamawiam tu!
												</NavButtonLink>
											</div>
										);
									})
							) : (
								<div className={styles.pickupPoint}>
									<p>Ładowanie ..</p>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className={styles.mapContainer}>
					{centerCoords !== null && (
						<MapContainer
							attributionControl={false}
							center={centerCoords}
							zoom={13}
							scrollWheelZoom={true}
							zoomControl={false}
							className={styles.map}
						>
							<SetViewOnClick coords={centerCoords} />
							<TileLayer
								attribution='<a target="_blank" href="https://leafletjs.com/">Leaflet</a> | &copy; Autorzy <a target="_blank" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
								url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
							/>
							<AttributionControl position="bottomright" prefix={false} />
							{response &&
								response.map((device, index) => {
									if (!device.coords) {
										return null;
									}
									return (
										<CustomMarkerShop
											key={index}
											open={location?.state?.searchCity ? false : device._id === "630bcaa4a99f2b06402cf75f"}
											position={[device.coords.lat, device.coords.lng]}
											device={{ id: device._id, location: device.locatedAt }}
										/>
									);
								})}
							<SearchControl
								provider={prov}
								showMarker={true}
								showPopup={false}
								maxMarkers={3}
								retainZoomLevel={false}
								animateZoom={true}
								autoClose={false}
								searchLabel={"Podaj szukany adres.."}
								keepResult={true}
								popupFormat={({ query, result }) => result.label}
								marker={CustomMarkerSearch}
							/>
						</MapContainer>
					)}
				</div>
			</div>
		</div>
	);
};

export default StorePage;
