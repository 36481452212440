import React from "react";
import logoUrl from "assets/mobile/HomePageLogo.svg";
import mobileStyles from "styles/mobile/mobile.module.css";
import styles from "styles/mobile/Home.module.css";
import buttonStyles from "styles/mobile/buttonStyles.module.css";
import NavButtonLink from "components/NavButtonLink";
import backgroundLogoUrl from "assets/mobile/backgroundLogo.svg";

const MobileHome = () => {
	return (
		<div className={[mobileStyles.center, styles.backgroundContainer].join(" ")}>
			<img
				style={{ top: 0, right: 0, transform: "translate(20%, -20%)" }}
				className={styles.backgroundLogo}
				src={backgroundLogoUrl}
				width={200}
				alt="logo"
			/>

			<img
				style={{ bottom: 0, right: 0, transform: "translate(20%, 20%)" }}
				className={styles.backgroundLogo}
				src={backgroundLogoUrl}
				width={200}
				alt="logo"
			/>

			<img
				style={{ bottom: 0, left: 0, transform: "translate(-30%, -10%)" }}
				className={styles.backgroundLogo}
				src={backgroundLogoUrl}
				width={200}
				alt="logo"
			/>

			<div className={styles.container}>
				<div className={[mobileStyles.center, mobileStyles.stretch].join(" ")}>
					<img style={{ zIndex: 2 }} src={logoUrl} alt="logo" />
				</div>
				<div className={[mobileStyles.center, mobileStyles.stretch].join(" ")}>
					<NavButtonLink to="/tutorial" className={[buttonStyles.gray, buttonStyles.bold].join(" ")}>
						Jak to działa?
					</NavButtonLink>
				</div>
				<div className={[mobileStyles.center, mobileStyles.spacing, mobileStyles.stretch].join(" ")}>
					<NavButtonLink to="/login" className={[buttonStyles.bold].join(" ")}>
						Zaloguj się
					</NavButtonLink>
					<NavButtonLink to="/signup" className={[buttonStyles.bold].join(" ")}>
						Zarejestruj się
					</NavButtonLink>
				</div>
			</div>
		</div>
	);
};

export default MobileHome;
