import React from "react";
import NavButtonLink from "components/NavButtonLink";
import styles from "styles/AccountPage.module.css";
import homeFormStyles from "styles/HomeForm.module.css";

const CardDetailsPage = () => {
	const inputStyles = { borderColor: "gray", color: "gray", letterSpacing: "1px", fontSize: "1.4em", padding: "0.1em" };
	const buttonStyles = { margin: "auto", marginTop: "30px", padding: "10px 50px", background: "var(--primary)", color: "white", fontSize: "1.4em" };

	return (
		<div className={styles.container}>
			<div>
				<form className={homeFormStyles.form}>
					<p className={styles.title}>Dodaj kartę i płać nią za zakupy w FoodBox i FoodBox Store.</p>
					<div className={styles.inputContainer}>
						<h2>Numer karty:</h2>
						<input defaultValue="5412 7512 3412 3456" disabled style={inputStyles} />
						<h2>Data ważności:</h2>
						<input defaultValue="12/23" disabled style={inputStyles} />
						<h2>CVC/CVV:</h2>
						<input defaultValue="819" disabled style={inputStyles} />
						<NavButtonLink style={buttonStyles} to="../user/account">
							Gotowe
						</NavButtonLink>
					</div>
				</form>
			</div>
		</div>
	);
};

export default CardDetailsPage;
