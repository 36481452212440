import React from "react";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import actionType from "constants/actionTypes";
import PathsAPI from "constants/PathsAPI";
import { useAuthContext } from "hooks/useAuthContext";
import { useFetch } from "hooks/useFetch";
import { useState } from "react";
import styles from "styles/mobile/Dashboard/User/Account.module.css";
import inputStyles from "styles/mobile/inputStyles.module.css";
import mobileStyles from "styles/mobile/mobile.module.css";

const AccountPage = () => {
	const [changed, setChanged] = useState(false);
	const { useGet, usePut } = useFetch(PathsAPI.UserDetails);
	const { response: userDetails } = useGet(true);
	const { request, response, loading, hasError } = usePut();
	const { user, dispatch } = useAuthContext();

	const handleDetectChanges = () => {
		!changed && setChanged(true);
	};

	const handleSubmitChanges = async (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		const userDetails = await request(formData);
		dispatch({ type: actionType.LOGIN, payload: { ...user, fname: userDetails.fname, email: userDetails.email } });
	};

	return (
		<div className={styles.container}>
			<div>
				<h1>Moje konto</h1>
				<h2>Dane użytkownika</h2>
				<form onSubmit={handleSubmitChanges}>
					<div className={[mobileStyles.center, mobileStyles.spacing].join(" ")}>
						<input
							className={[inputStyles.gray, inputStyles.round].join(" ")}
							required
							defaultValue={userDetails?.fname}
							onChange={handleDetectChanges}
							name="fname"
							placeholder="Imię"
						/>
						<input
							className={[inputStyles.gray, inputStyles.round].join(" ")}
							required
							defaultValue={userDetails?.lname}
							onChange={handleDetectChanges}
							name="lname"
							placeholder="Nazwisko"
						/>
						<input
							className={[inputStyles.gray, inputStyles.round].join(" ")}
							required
							defaultValue={userDetails?.email}
							onChange={handleDetectChanges}
							name="email"
							placeholder="Email"
						/>
						<input
							className={[inputStyles.gray, inputStyles.round].join(" ")}
							required
							defaultValue={userDetails?.phone}
							onChange={handleDetectChanges}
							name="phone"
							placeholder="Numer telefonu"
						/>
						<Button style={changed ? { background: "var(--primary)", color: "#fff" } : {}} disabled={!changed} loading={loading}>
							{changed ? "Zapisz zmiany" : "Zmień dane, apy zapisać zmiany"}
						</Button>
						<br />
						{hasError && <div className="error">{hasError}</div>}
						{response?._id && <div>Zmiany zostały zapisane</div>}
					</div>
				</form>
			</div>
			<div className={styles.buttonContainer}>
				<NavButtonLink to="/dashboard/password/change">Zmień hasło</NavButtonLink>
				<NavButtonLink to="/dashboard/helpdesk">Pomoc</NavButtonLink>
			</div>
		</div>
	);
};

export default AccountPage;
