import React from "react";
import PathsAPI from "constants/PathsAPI";
import { useFetch } from "hooks/useFetch";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import styles from "styles/desktop/KioskProductsPage.module.css";

const KioskProductsPage = () => {
	const { useGet: useKioskGet } = useFetch(PathsAPI.KioskList);
	const { response: kioskProductsList, loading } = useKioskGet(true);

	const [filteredProducts, setFilteredProducts] = useState([]);

	const refreshColorAccent = () => {
		document.documentElement.style.setProperty("--background-color", "white");
		document.documentElement.style.setProperty("--background-accent", "#e7e7e7");
	};

	useEffect(refreshColorAccent, [refreshColorAccent]);

	useEffect(() => {
		if (!kioskProductsList) {
			return;
		}

		let tempProducts = {};

		for (const [boxId, box] of Object.entries(kioskProductsList.device.boxes)) {
			if (!box.vendingOfferId) {
				continue;
			}

			const offer = kioskProductsList.vendingOffers.find((offer) => String(offer._id) === box.vendingOfferId);

			for (const product of offer.products) {
				if (!tempProducts[product._id]) {
					tempProducts[product._id] = { ...product, boxes: [] };
				}

				tempProducts[product._id].boxes.push(boxId);
			}
		}

		setFilteredProducts(Object.values(tempProducts));
	}, [kioskProductsList]);

	return (
		<div className={styles.container}>
			<div className={styles.mainContainer}>
				<div className={styles.productsContainer}>
					<div className={styles.products}>
						{loading ? (
							<h3 style={{ textAlign: "center" }}>Ładowanie ..</h3>
						) : (
							<>
								{!filteredProducts ? (
									<h3 style={{ textAlign: "center" }}>Ładowanie ..</h3>
								) : filteredProducts.length > 0 ? (
									filteredProducts.map((product) => (
										<div key={`box-${product._id}`} className={styles.productContainer}>
											<img
												loading="lazy"
												alt={product.name}
												src={product.photoUrl}
												onError={(e) => {
													e.target.src = "/404.png";
												}}
											></img>
											<div className={styles.productInfo}>
												<div className={styles.productTitle}>
													<b style={{ fontSize: "1.5em" }}>{product.name}</b>
													<br />
													<div style={{ fontWeight: 500, fontSize: "1em" }}>{product.desc}</div>
												</div>
												<b style={{ fontSize: "1.5em" }}>{Number(product.brutto).toFixed(2)} zł</b>
											</div>
											<div style={{ background: "transparent" }} className={styles.productAvailabilityContainer}>
												<div className={styles.productInKioskContainer}>
													Skrytk{product.boxes.length > 1 ? "i" : "a"}
													<br />
													<span style={{ fontSize: "3em" }}>{product.boxes.join(", ")}</span>
												</div>
											</div>
										</div>
									))
								) : (
									<h3 style={{ textAlign: "center" }}>
										Brak dostępnych produktów
										<br />w tej kategorii
									</h3>
								)}
							</>
						)}
					</div>
				</div>
			</div>

			<Outlet />
		</div>
	);
};

export default KioskProductsPage;
