import React from "react";
import PathsAPI from "constants/PathsAPI";
import { useState } from "react";

export const useSignup = () => {
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(null);
	const [success, setSuccess] = useState(null);

	const signup = async (formData) => {
		setIsLoading(true);
		setError(null);

		const response = await fetch(PathsAPI.Signup, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				email: formData.get("email"),
				password: formData.get("password"),
				details: {
					lname: formData.get("lname"),
					fname: formData.get("fname"),
					phone: formData.get("phone"),
				},
			}),
		});

		const json = await response.json();

		if (!response.ok) {
			setIsLoading(false);
			setError(json.error);
		}
		if (response.ok) {
			setIsLoading(false);
			setSuccess(true);
		}
	};

	return { signup, isLoading, success, error };
};
