const timeToDate = (time) => {
	const resultDate = new Date();

	if (!time) {
		return resultDate;
	}

	const [hours, minutes] = time.split(":");

	resultDate.setHours(hours);
	resultDate.setMinutes(minutes);
	resultDate.setSeconds(0);
	resultDate.setMilliseconds(0);

	return resultDate;
};

export default timeToDate;
