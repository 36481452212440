import React from "react";

import iconStep1 from 'assets/icons/icon-phone.svg'
import iconStep2 from 'assets/icons/icon-email.svg'
import iconStep3 from 'assets/icons/icon-localization-green.svg'

import HelpdeskPage from 'styles/desktop/HelpdeskPage.module.css'

import Heading from 'components/Heading.js'

const Helpdesk = () => {
    return (
        <div className={HelpdeskPage.containerParent}>
            <div className={HelpdeskPage.container}>
                <Heading>Masz pytania?</Heading>
                <div className={HelpdeskPage.info}>
                    <img className={HelpdeskPage.svg} src={iconStep1} alt="step1icon"/>
                    <h2>+48 607 666 958</h2>
                </div>
                <div className={HelpdeskPage.info}>
                    <img className={HelpdeskPage.svg} src={iconStep2} alt="step2icon"/>
                    <h2>sekretariat@itecom.pl</h2>
                </div>
                <div className={HelpdeskPage.info}>
                    <img className={HelpdeskPage.svg} src={iconStep3} alt="step3icon"/>
                    <h2>Wróżewy 39, 63-700 Krotoszyn</h2>
                </div>            
            </div>
        </div>
    );
}

export default Helpdesk;