const formatDateTime = (date, format = "datetime") => {
	let result = "";
	if (format === "date" || format === "datetime") {
		if (date.getDate() < 10) {
			result += "0";
		}
		result += date.getDate() + ".";
		if (date.getMonth() + 1 < 10) {
			result += "0";
		}
		result += date.getMonth() + 1 + ".";
		result += date.getFullYear() + " ";
	}
	if (format === "time" || format === "datetime") {
		if (date.getHours() < 10) {
			result += "0";
		}
		result += date.getHours() + ":";
		if (date.getMinutes() < 10) {
			result += "0";
		}
		result += date.getMinutes();
	}

	return result;
};

export default formatDateTime;
