import React from "react";
import PathsAPI from "constants/PathsAPI";
import { useFetch } from "hooks/useFetch";
import { useState } from "react";
import styles from "styles/mobile/PasswordChange.module.css";
import { toast } from "react-toastify";

const SaveButton = ({ children, refresh, loading = false, error, className, ...props }) => {
	return (
		<button
			className={`${styles.editButton} ${refresh ? styles.refreshButton : ""} ${error ? styles.errorButton : ""} ${
				loading ? styles.loadingButton : ""
			}  ${className ? className : ""}`}
			disabled={loading}
			{...props}
		>
			{children}
			<span className={styles.notification} name={refresh ? "show" : ""}>
				Zalecane odświeżenie danych
			</span>
		</button>
	);
};

const PasswordChange = () => {
	const { usePut } = useFetch(PathsAPI.UserPasswordChange);
	const { request, response, loading, hasError } = usePut();
	const [passwordCheck, setPasswordCheck] = useState({ minLength: false, minLowercase: false, minUppercase: false, minNumbers: false, minSymbols: false });

	const handleSubmitChanges = async (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		const response = await request(formData);

		response.status ? toast.success("Hasło zostało zmienione") : toast.error("Nieprawidłowe aktualne hasło");
	};

	const handleCheckPassword = (e) => {
		const checkValue = e.target.value;
		let result = { minLength: false, minLowercase: false, minUppercase: false, minNumbers: false, minSymbols: false };

		if (checkValue.length >= 8) {
			result.minLength = true;
		}

		if (checkValue !== checkValue.toUpperCase()) {
			result.minLowercase = true;
		}

		if (checkValue !== checkValue.toLowerCase()) {
			result.minUppercase = true;
		}

		if (/\d/.test(checkValue)) {
			result.minNumbers = true;
		}

		if (/[^\p{L}\d\s]/u.test(checkValue)) {
			result.minSymbols = true;
		}

		setPasswordCheck(result);
	};

	return (
		<div className={styles.container}>
			<div className={styles.dialogue}>
				<div className={styles.titles}>
					<h1 className={styles.myAccountTitle}>Moje konto</h1>
					<h2 className={styles.myAccountNotice}>Zmiana hasła</h2>
				</div>
				<form onSubmit={handleSubmitChanges}>
					<div className={styles.inputContainer}>
						<input className={styles.input} required name="currentPassword" type="password" placeholder="Aktualne hasło" />
						<input className={styles.input} required onChange={handleCheckPassword} name="newPassword" type="password" placeholder="Nowe hasło" />

						<div className={styles.passwordCheckContainer}>
							<h2 style={{ marginBottom: 0 }}>Wymagania hasła:</h2>
							<ul>
								<li style={{ color: passwordCheck.minLength ? "green" : "red" }}>
									<h2 style={{ margin: 0 }}>Minimum 8 znaków</h2>
								</li>
								<li style={{ color: passwordCheck.minLowercase ? "green" : "red" }}>
									<h2 style={{ margin: 0 }}>Małe litery</h2>
								</li>
								<li style={{ color: passwordCheck.minUppercase ? "green" : "red" }}>
									<h2 style={{ margin: 0 }}>Duże litery</h2>
								</li>
								<li style={{ color: passwordCheck.minNumbers ? "green" : "red" }}>
									<h2 style={{ margin: 0 }}>Cyfry</h2>
								</li>
								<li style={{ color: passwordCheck.minSymbols ? "green" : "red" }}>
									<h2 style={{ margin: 0 }}>Znaki specjalne</h2>
								</li>
							</ul>
						</div>
						<SaveButton loading={loading}>Zapisz</SaveButton>
						{hasError && <div className="error">{hasError}</div>}
						{response?._id && <div>Zmiany zostały zapisane</div>}
					</div>
				</form>
			</div>
		</div>
	);
};

export default PasswordChange;
