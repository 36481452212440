import React from "react";
import { Link } from "react-router-dom"
import actionType from "constants/actionTypes";
import PathsAPI from "constants/PathsAPI";
import { useAuthContext } from "hooks/useAuthContext";
import { useFetch } from "hooks/useFetch";
import { useState } from "react";
import styles from "styles/AccountPage.module.css";

import Heading from "components/Heading";

const EditButton = ({ children, refresh, loading = false, error, className, ...props }) => {
	return (
		<button
			className={`${styles.editButton} ${refresh ? styles.refreshButton : ""} ${error ? styles.errorButton : ""} ${loading ? styles.loadingButton : ""}  ${className ? className : ""}`}
			disabled={loading}
			{...props}
		>
			{children}
			<span className={styles.notification} name={refresh ? "show" : ""}>
				Zalecane odświeżenie danych
			</span>
		</button>
	);
};

const AccountPage = () => {
	const [changed, setChanged] = useState(false);
	const { useGet, usePut } = useFetch(PathsAPI.UserDetails);
	const { response: userDetails } = useGet(true);
	const { request, response, loading, hasError } = usePut();
	const { user, dispatch } = useAuthContext();

	const handleDetectChanges = () => {
		!changed && setChanged(true);
	};

	const handleSubmitChanges = async (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		const userDetails = await request(formData);
		dispatch({ type: actionType.LOGIN, payload: { ...user, fname: userDetails.fname, email: userDetails.email } });
	};

	return (
		<div className={styles.container}>
			<div className={styles.wrapper}>
				<div className={styles.buttonContainer}>
					{/* <NavButtonLink className={styles.primaryButton} to="orders">
						Moje zamówienia
					</NavButtonLink> */}
					{/* <NavButtonLink className={styles.primaryButton} to="card-details">
						Moja karta
					</NavButtonLink> */}
				</div>
				{/* <img loading="lazy" className={styles.payCard} src={CardIcon} alt="card" /> */}
				<Heading>Moje konto</Heading>
				<form onSubmit={handleSubmitChanges}>
					<div className={styles.inputContainer}>
						<input
							className={styles.input}
							required
							defaultValue={userDetails?.fname}
							onChange={handleDetectChanges}
							name="fname"
							placeholder="Imię"
						/>
						<input
							className={styles.input}
							required
							defaultValue={userDetails?.lname}
							onChange={handleDetectChanges}
							name="lname"
							placeholder="Nazwisko"
						/>
						<input
							className={styles.input}
							required
							defaultValue={userDetails?.email}
							onChange={handleDetectChanges}
							name="email"
							placeholder="Email"
						/>
						<input
							className={styles.input}
							required
							defaultValue={userDetails?.phone}
							onChange={handleDetectChanges}
							name="phone"
							placeholder="Numer telefonu"
						/>
						<EditButton disabled={!changed} loading={loading}>
							{changed ? "Zapisz zmiany" : "Edytuj"}
						</EditButton>
						{hasError && <div className="error">{hasError}</div>}
						{response?._id && <div>Zmiany zostały zapisane</div>}
					</div>
				</form>
				<div className={styles.linksContainer}>
					<p>
						<Link to="/dashboard/password/change">Zmień hasło</Link>
					</p>
					<p>
						<Link to="/dashboard/helpdesk">Pomoc</Link>
					</p>
				</div>
			</div>
		</div>
	);
};

export default AccountPage;
