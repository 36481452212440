import React from "react";
import { useSignup } from "hooks/useSignup";
import styles from "styles/mobile/Signup.module.css";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import inputStyles from "styles/mobile/inputStyles.module.css";
import backgroundLogoUrl from "assets/mobile/backgroundLogo.svg";

const MobileSignup = () => {
	const { signup, error, success, isLoading } = useSignup();
	let navigate = useNavigate();

	const [passwordCheck, setPasswordCheck] = useState({ minLength: false, minLowercase: false, minUppercase: false, minNumbers: false, minSymbols: false });

	const handleSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);

		await signup(formData);
	};

	useEffect(() => {
		success && navigate("/login");
	}, [success, navigate]);

	const handleCheckPassword = (e) => {
		const checkValue = e.target.value;
		let result = { minLength: false, minLowercase: false, minUppercase: false, minNumbers: false, minSymbols: false };

		if (checkValue.length >= 8) {
			result.minLength = true;
		}

		if (checkValue !== checkValue.toUpperCase()) {
			result.minLowercase = true;
		}

		if (checkValue !== checkValue.toLowerCase()) {
			result.minUppercase = true;
		}

		if (/\d/.test(checkValue)) {
			result.minNumbers = true;
		}

		if (/[^\p{L}\d\s]/u.test(checkValue)) {
			result.minSymbols = true;
		}

		setPasswordCheck(result);
	};

	const inputStyle = [inputStyles.gray, inputStyles.round].join(" ");

	return (
		<>
			<div className={styles.container}>
				<h1>Zarejestruj się</h1>
				<form className={styles.form} onSubmit={handleSubmit}>
					<input className={inputStyle} type="text" required name="fname" placeholder="Imię" />
					<input className={inputStyle} type="text" required name="lname" placeholder="Nazwisko" />
					<input className={inputStyle} type="email" required name="email" placeholder="Email" />
					<input className={inputStyle} type="text" required name="phone" placeholder="Numer telefonu" />
					<input
						className={inputStyle}
						onChange={handleCheckPassword}
						type="password"
						required
						name="password"
						placeholder="Hasło"
						autoComplete="new-password"
					/>
					<div className={styles.passwordCheckContainer}>
						<h2>Wymagania hasła:</h2>
						<ul>
							<li style={{ color: passwordCheck.minLength ? "green" : "red" }}>
								<p >minimum 8 znaków</p>
							</li>
							<li style={{ color: passwordCheck.minLowercase ? "green" : "red" }}>
								<p >małe litery</p>
							</li>
							<li style={{ color: passwordCheck.minUppercase ? "green" : "red" }}>
								<p>duże litery</p>
							</li>
							<li style={{ color: passwordCheck.minNumbers ? "green" : "red" }}>
								<p >cyfry</p>
							</li>
							<li style={{ color: passwordCheck.minSymbols ? "green" : "red" }}>
								<p >znaki specjalne</p>
							</li>
						</ul>
					</div>
					<span className={styles.error}>{error?.message}</span>
					<div className={styles.rulesCheckboxContainer}>
						<fieldset style={{ marginTop: "1em" }}>
							<label htmlFor="statute">
								Regulamin (
								<a target="_blank" rel="noreferrer" href="/terms-and-conditions.pdf">
									sprawdź
								</a>
								)
							</label>
							<span />
							<input type="checkbox" name="statute" id="statute" required />
						</fieldset>
						<fieldset>
							<label htmlFor="private-policy">
								Polityka prywatności (
								<a target="_blank" rel="noreferrer" href="/private-policy.pdf">
									sprawdź
								</a>
								)
							</label>
							<span />
							<input type="checkbox" name="private-policy" id="private-policy" required />
						</fieldset>
					</div>

					<Button className={styles.signUpButton} loading={isLoading}>
						Zarejestruj się
					</Button>
					<NavButtonLink className={styles.returnButton} to="/">
						Powrót
					</NavButtonLink>
				</form>
			</div>
			<div className={styles.backgroundContainer}>
				<img
					style={{ top: 0, right: 0, transform: "translate(20%, -20%)" }}
					className={styles.backgroundLogo}
					src={backgroundLogoUrl}
					width={200}
					alt="logo"
				/>

				<img
					style={{ bottom: 0, right: 0, transform: "translate(20%, 20%)" }}
					className={styles.backgroundLogo}
					src={backgroundLogoUrl}
					width={200}
					alt="logo"
				/>

				<img
					style={{ bottom: 0, left: 0, transform: "translate(-30%, -10%)" }}
					className={styles.backgroundLogo}
					src={backgroundLogoUrl}
					width={200}
					alt="logo"
				/>
			</div>
		</>

	);
};

export default MobileSignup;
