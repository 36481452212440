import React from "react";
import actionType from "constants/actionTypes";
import { useAuthContext } from "./useAuthContext";
import PathsAPI from "constants/PathsAPI";

export const useLogout = () => {
	const { dispatch } = useAuthContext();

	const logout = async () => {
		const resp = await fetch(PathsAPI.Logout);

		if (resp.ok) {
			const { status } = await resp.json();

			if (status === "ok") {
				dispatch({ type: actionType.LOGOUT });
			}
		}
	};

	return { logout };
};
