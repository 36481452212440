import React from "react";
import NavButtonLink from "components/NavButtonLink";
import getOrderStatus from "components/orderStatus";
import PathsAPI from "constants/PathsAPI";
import stateMode from "constants/stateMode";
import { useFetch } from "hooks/useFetch";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import styles from "styles/mobile/Dashboard/User/Orders.module.css";

const OrdersPage = () => {
	const { useGet } = useFetch(PathsAPI.UserOrders);
	const { response: ordersList, request: refreshOrdersList, loading } = useGet(true);
	const location = useLocation();

	const calcSummaryCost = (orderCart) => {
		let newSummary = 0;
		orderCart.forEach((product) => {
			const amount = product.hasOwnProperty("amount") ? product.amount : 1;
			newSummary += amount * product.brutto;
		});
		return newSummary.toFixed(2);
	};

	useEffect(() => {
		if (location.state === stateMode.Refresh) {
			refreshOrdersList();
			location.state = null;
		}
	}, [location, ordersList, refreshOrdersList]);

	const viewReceiveButton = (order) => {
		if (getOrderStatus(order).return) {
			return false;
		}

		return order.statusDelivery.length > 0 && !getOrderStatus(order).received > 0;
	};

	return (
		<div className={styles.container}>
			<h1>Zamówienia</h1>
			{ordersList && ordersList.length > 0 ? (
				ordersList.map((order, index) => (
					<div className={styles.orderContainer} key={index}>
						<div>
							<p>
								<b>Status</b>
								<br />
								<span style={{ color: "var(--primary)" }}>{getOrderStatus(order).message}</span>
							</p>
							<p>
								<b>Data</b>
								<br />
								{order.createdAt.slice(0, 10).split("-").reverse().join(".")}
							</p>
							<p>
								<b>ID zamówienia</b>
								<br />
								{order._id.substr(18)}
							</p>
						</div>
						<span className={styles.divider} />
						<div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
							<div>
								<p>
									<b>Zamówienie:</b>
									<br />
								</p>

								{order.cartList?.map((product, index) => (
									<p key={index}>
										{product.name} {product?.amount && <>x{product.amount}</>}
									</p>
								))}
							</div>

							<span style={{ display: "flex", flexDirection: "column", fontWeight: "bold", fontSize: "1.5rem", marginBottom: "14px" }}>
								{calcSummaryCost(order.cartList)} zł
							</span>
						</div>
						{viewReceiveButton(order) && (
							<NavButtonLink
								to={`pick-up/${order._id.substr(18)}`}
								state={{ order }}
								style={{ background: "var(--primary)", color: "white", gridColumn: "1 / 4", boxShadow: "none" }}
								disabled={order.received}
							>
								Odbierz
							</NavButtonLink>
						)}
					</div>
				))
			) : loading ? (
				<h2>Ładowanie ..</h2>
			) : (
				<h2>Pusto tutaj ..</h2>
			)}
			<Outlet />
		</div>
	);
};

export default OrdersPage;
