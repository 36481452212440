import React from "react";
import { Outlet, useLocation, useMatch, useResolvedPath } from "react-router-dom";
import styles from "styles/desktop/components/Layout/Layout.module.css";
import { ReactComponent as Icon } from "assets/lemon.svg";
import FoodBoxLogo from "assets/logo.svg";
import { useEffect, useState } from "react";
import buttonStyles from "styles/desktop/buttonStyles.module.css";
import { useAuthContext } from "hooks/useAuthContext";
import NavItem from "./NavItem";
import NavButtonLink from "./NavButtonLink";
import { useNavigate } from "react-router-dom";

const Layout = () => {
	let resolved = useResolvedPath("/");
	let match = useMatch({ path: resolved.pathname, end: true });
	const [hideNavbar, setHideNavbar] = useState(false);
	const location = useLocation();
	const { user } = useAuthContext();
	const navigate = useNavigate();

	useEffect(() => {
		setHideNavbar(["/login", "/signup", "/password/forget", "/kiosk"].includes(location.pathname) || location.pathname.startsWith("/password/reset/"));

		if (typeof window.ReactNativeWebView === "object") {
			window.ReactNativeWebView.postMessage(location.pathname);
		}
	}, [location]);

	return (
		<div className={styles.container}>
			<div className={styles.background}>
				<Icon name="top-left" />
				<Icon name="bottom-left" />
				<Icon name="bottom-right" />
			</div>
			<div className={`${match ? styles.startpage : hideNavbar ? styles.hiddenNavbar : ""} ${styles.titleContainer}`}>
				<div className={styles.foodBoxLogoContainer} onClick={() => navigate("/")}>
					<img src={FoodBoxLogo} className={styles.foodBoxLogo} style={{ height: match ? "8rem" : "3rem" }} alt="online" />
					<span>FoodBox</span>
				</div>
				<div className={styles.navbarIcons} style={match || hideNavbar ? { display: "none" } : {}}>
					<NavItem to="/dashboard/home" title="Zamów Teraz!" bold />
					<NavItem to="/dashboard/tutorial" title="Jak to działa?" />
					<NavItem to="/dashboard/user/account/orders" title="Zamówienia" />
					<NavItem to="/dashboard/user/account" title="Moje konto" />

					<NavItem to="/dashboard/helpdesk" title="Kontakt" />
					{user ? (
						<NavItem to="/logout" title="Wyloguj" />
					) : (
						<NavButtonLink
							to="/"
							className={buttonStyles.primary}
							style={{
								fontSize: "2em",
								fontWeight: 500,
								padding: "0.5em 1em",
							}}
						>
							Zaloguj się
						</NavButtonLink>
					)}
				</div>
			</div>
			<Outlet />
		</div>
	);
};

export default Layout;
