import timeToDate from "constants/timeToDate";

class AvailabilityChecker {
	constructor(orderDate, shopsInfo = false) {
		this.orderDate = orderDate;
		this.shopsInfo = shopsInfo;
	}

	#getWeekDay = (date = new Date(this.orderDate)) => {
		return date.toLocaleString("pl", { weekday: "short" }).toUpperCase().slice(0, -1);
	};

	#checkAvailabilityWeekDays = (product, weekDays, checkedDate = this.orderDate) => {
		for (let i = 1; i <= 7; i++) {
			let nextDay = new Date(checkedDate);
			nextDay.setDate(nextDay.getDate() + i);

			if (nextDay < new Date(product.startDeliveryDate)) {
				nextDay = new Date(product.startDeliveryDate);
			}

			if (nextDay > new Date(product.endDeliveryDate)) {
				nextDay = new Date(product.endDeliveryDate);
			}

			if (weekDays.includes(this.#getWeekDay(nextDay))) {
				return nextDay;
			}
		}

		return new Date(product.endDeliveryDate);
	};

	checkAvailability = (product) => {
		let potentialStartDeliveryDate = new Date(product.startDeliveryDate);

		const shopInfo = this.shopsInfo ? this.shopsInfo.find((shopInfo) => shopInfo._id === product.supplier) : product.shopName;

		if (!shopInfo.weekDays.includes(this.#getWeekDay())) {
			potentialStartDeliveryDate = this.#checkAvailabilityWeekDays(product, shopInfo.weekDays);
		}

		if (this.orderDate.toDateString() === new Date().toDateString() && new Date() > timeToDate(shopInfo.maxOrdering)) {
			potentialStartDeliveryDate = this.#checkAvailabilityWeekDays(product, shopInfo.weekDays);
		}

		if (potentialStartDeliveryDate.getTime() === new Date(product.startDeliveryDate).getTime()) {
			potentialStartDeliveryDate.setHours(0, 0, 0, 0);
		}

		if (this.orderDate < potentialStartDeliveryDate) {
			return potentialStartDeliveryDate;
		}

		return true;
	};
}

export default AvailabilityChecker;
